@media (max-width: 1850px){
    .home_slider{
        .item{
            height: auto;
            img{
                height: auto;
                width: 100% !important;
                display: inline-block;
            }
            .slide_caption{
                h2{
                }
                p{
                }
                .btn{
                    & + .pkg_price{
                    }
                    &:hover{
                        & + .pkg_price{
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{            
                div{
                    &.owl-prev{
                    }
                    &.owl-next{
                    }
                }
            }
        }

        &.home_slider2{
            .item{
                .slide_caption{
                    .sTexts{
                        h5{
                        }
                        h2{
                        }
                        ul{
                            li{
                                & + li{
                                }
                            }
                        }
                        .btn{
                            & + .pkg_price{
                            }
                        }
                    }
                    .sImgs{
                        .row{
                        }
                        img{
                        }
                        &.pr{
                        }
                    }
                }
            }
        }
    }

    #home_slider3{
        .carousel-indicators{
            li{
                & + li{
                }
                img{
                }
                &.active{
                }
            }
        }
        .carousel-inner{
            .item{
                .slide_bg{
                }
                .carousel-caption{
                    .container{
                        .media{
                            .media-left{
                            }
                            .media-body{
                                h4{
                                }
                                h2{
                                }
                                p{
                                }
                                .btn{
                                    & + .pkg_price{
                                    }
                                    &:hover{
                                        & + .pkg_price{
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .carousel-control{
            &.left{}
            &.right{}
        }
    }
}

@media (max-width: 1799px){
    .home_slider{
        .item{
            img{
            }
            .slide_caption{
                h2{
                }
                p{
                }
                .btn{
                    & + .pkg_price{
                    }
                    &:hover{
                        & + .pkg_price{
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{            
                div{
                    &.owl-prev{
                    }
                    &.owl-next{
                    }
                }
            }
        }

        &.home_slider2{
            .item{
                .slide_caption{
                    .sTexts{
                        h5{
                        }
                        h2{
                        }
                        ul{
                            li{
                                & + li{
                                }
                            }
                        }
                        .btn{
                            & + .pkg_price{
                            }
                        }
                    }
                    .sImgs{
                        max-width: 320px;
                        .row{
                        }
                        img{
                        }
                        &.pr{
                            bottom: 0;
                            padding-left: 50px;
                        }
                    }
                }
            }
        }
    }

    #home_slider3{
        .carousel-indicators{
            li{
                & + li{
                }
                img{
                }
                &.active{
                }
            }
        }
        .carousel-inner{
            .item{
                .slide_bg{
                }
                .carousel-caption{
                    .container{                        
                        .media{
                            .media-left{
                            }
                            .media-body{
                                h4{
                                }
                                h2{
                                }
                                p{
                                }
                                .btn{
                                    & + .pkg_price{
                                    }
                                    &:hover{
                                        & + .pkg_price{
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .carousel-control{
            &.left{}
            &.right{}
        }
    }
}

@media (max-width: 1499px){
    .home_slider{
        .item{
            img{
            }
            .slide_caption{
                h2{
                }
                p{
                }
                .btn{
                    & + .pkg_price{
                    }
                    &:hover{
                        & + .pkg_price{
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{            
                div{
                    &.owl-prev{
                    }
                    &.owl-next{
                    }
                }
            }
        }

        &.home_slider2{
            .item{
                .slide_caption{
                    .sTexts{
                        padding-top: 120px;
                        h5{
                        }
                        h2{
                        }
                        ul{
                            li{
                                & + li{
                                }
                            }
                        }
                        .btn{
                            & + .pkg_price{
                            }
                        }
                    }
                    .sImgs{
                        max-width: 280px;
                        .row{
                        }
                        img{
                        }
                        &.pr{
                            padding-left: 30px;
                        }
                    }
                }
            }
        }
    }

    #home_slider3{
        .carousel-indicators{
            li{
                & + li{
                }
                img{
                }
                &.active{
                }
            }
        }
        .carousel-inner{
            .item{
                .slide_bg{
                }
                .carousel-caption{
                    .container{
                        .media{
                            .media-left{
                            }
                            .media-body{
                                h4{
                                }
                                h2{
                                }
                                p{
                                }
                                .btn{
                                    & + .pkg_price{
                                    }
                                    &:hover{
                                        & + .pkg_price{
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .carousel-control{
            &.left{}
            &.right{}
        }
    }
}

@media (max-width: 1400px){
    .home_slider{
        .item{
            .slide_caption{
                h2{
                }
                p{
                }
                .btn{
                    & + .pkg_price{
                    }
                    &:hover{
                        & + .pkg_price{
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{            
                div{
                    &.owl-prev{
                    }
                    &.owl-next{
                    }
                }
            }
        }

        &.home_slider2{
            .item{
                .slide_caption{
                    .sTexts{
                        h5{
                        }
                        h2{
                        }
                        ul{
                            li{
                                & + li{
                                    margin-top: 15px;
                                }
                            }
                        }
                        .btn{
                            & + .pkg_price{
                            }
                        }
                    }
                    .sImgs{
                        .row{
                        }
                        img{
                        }
                        &.pr{
                        }
                    }
                }
            }
        }
    }

    #home_slider3{
        .carousel-indicators{
            width: 970px;
            margin-left: -485px;
            li{
                height: 104px;
                padding: 30px;
                & + li{
                }
                img{
                }
                &.active{
                }
            }
        }
        .carousel-inner{
            .item{
                .slide_bg{
                }
                .carousel-caption{
                    .container{
                        .media{
                            .media-left{
                            }
                            .media-body{
                                h4{
                                }
                                h2{
                                }
                                p{
                                }
                                .btn{
                                    & + .pkg_price{
                                    }
                                    &:hover{
                                        & + .pkg_price{
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .carousel-control{
            &.left{}
            &.right{}
        }
    }
}

@media (max-width: 1299px){
    .home_slider{
        .item{
            .slide_caption{
                h2{
                }
                p{
                }
                .btn{
                    & + .pkg_price{
                    }
                    &:hover{
                        & + .pkg_price{
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{            
                div{
                    &.owl-prev{
                    }
                    &.owl-next{
                    }
                }
            }
        }

        &.home_slider2{
            .item{
                .slide_caption{
                    .sTexts{
                        h5{
                        }
                        h2{
                        }
                        ul{
                            li{
                                & + li{
                                }
                            }
                        }
                        .btn{
                            & + .pkg_price{
                            }
                        }
                    }
                    .sImgs{
                        width: 260px;
                        .row{
                        }
                        img{
                        }
                        &.pr{
                        }
                    }
                }
            }
        }
    }

    #home_slider3{
        .carousel-indicators{
            li{
                & + li{
                }
                img{
                }
                &.active{
                }
            }
        }
        .carousel-inner{
            .item{
                .slide_bg{
                }
                .carousel-caption{
                    .container{
                        .media{
                            .media-left{
                            }
                            .media-body{
                                h4{
                                }
                                h2{
                                }
                                p{
                                }
                                .btn{
                                    & + .pkg_price{
                                    }
                                    &:hover{
                                        & + .pkg_price{
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .carousel-control{
            &.left{}
            &.right{}
        }
    }
}

@media (max-width: 1199px){
    .home_slider{
        .item{
            .slide_caption{
                h2{
                }
                p{
                }
                .btn{
                    & + .pkg_price{
                    }
                    &:hover{
                        & + .pkg_price{
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{            
                div{
                    &.owl-prev{
                    }
                    &.owl-next{
                    }
                }
            }
        }

        &.home_slider2{
            .item{
                .slide_caption{
                    .sTexts{
                        padding-left: 50px;
                        padding-top: 50px;
                        h5{
                        }
                        h2{
                        }
                        ul{
                            li{
                                & + li{
                                    margin-top: 15px;
                                }
                            }
                        }
                        .btn{
                            & + .pkg_price{
                            }
                        }
                    }                    
                    .sImgs{
                        width: 220px;
                        .row{
                        }
                        img{
                        }
                        &.pr{
                        }
                    }
                }
            }
        }
    }

    #home_slider3{
        .carousel-indicators{
            width: 970px;
            margin-left: -485px;
            li{
                height: 104px;
                padding: 30px;
                & + li{
                }
                img{
                }
                &.active{
                }
            }
        }
        .carousel-inner{
            .item{
                .slide_bg{
                }
                .carousel-caption{
                    .container{
                        .media{
                            .media-left{
                            }
                            .media-body{
                                h4{
                                }
                                h2{
                                    font-size: 59px;
                                    line-height: 55px;
                                }
                                p{
                                }
                                .btn{
                                    & + .pkg_price{
                                    }
                                    &:hover{
                                        & + .pkg_price{
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .carousel-control{
            &.left{}
            &.right{}
        }
    }
}

@media (max-width: 991px){
    .home_slider{
        .item{
            .slide_caption{
                h2{
                }
                p{
                }
                .btn{
                    & + .pkg_price{
                    }
                    &:hover{
                        & + .pkg_price{
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{            
                div{
                    &.owl-prev{
                    }
                    &.owl-next{
                    }
                }
            }
        }

        &.home_slider2{
            .item{
                .slide_caption{
                    .sTexts{
                        padding-top: 20px;
                        h5{
                        }
                        h2{
                            font-size: 40px;
                            font-weight: 500;
                            margin-bottom: 15px;
                        }
                        ul{
                            margin-bottom: 15px;
                            li{
                                & + li{
                                    margin-top: 10px;
                                }
                            }
                        }
                        .btn{
                            & + .pkg_price{
                            }
                        }
                    }
                    .sImgs{
                        width: 165px;
                    }
                }
            }
        }
    }

    #home_slider3{
        .carousel-indicators{
            width: 750px;
            margin-left: -375px;
            li{
                padding: 20px;
                height: 84px;
                & + li{
                }
                img{
                    margin-right: 15px;
                }
                &.active{
                }
            }
        }
        .carousel-inner{
            .item{
                height: 535px;
                .carousel-caption{
                    height: 418px;
                    .container{
                        .media{
                            .media-left{
                            }
                            .media-body{
                                padding-top: 100px;
                                padding-bottom: 100px;
                                h4{
                                }
                                h2{
                                    font-size: 44px;
                                    line-height: 40px;
                                }
                                p{
                                    margin-top: 10px;
                                    line-height: 20px;
                                    font-size: 14px;
                                }
                                .btn{
                                    margin-top: 15px;
                                    & + .pkg_price{
                                        margin-top: 15px;
                                    }
                                    &:hover{
                                        & + .pkg_price{
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .carousel-control{
            &.left{}
            &.right{}
        }
    }
}

@media (max-width: 767px){
    .home_slider{
        .item{
            .slide_caption{
                text-align: center;
                top: ~"calc(50% - 60px)";
                h2{
                    font-size: 50px;
                }
                p{
                    display: none;
                }
                .btn{
                    float: none;
                    & + .pkg_price{
                        display: none;
                    }
                    &:hover{
                        & + .pkg_price{
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{            
                div{
                    &.owl-prev{
                    }
                    &.owl-next{
                    }
                }
            }
        }

        &.home_slider2{
            .item{
                .slide_caption{
                    top: ~"calc(50% - 60px)";
                    bottom: auto;
                    .sTexts{
                        padding: 0;
                        width: 100%;
                        h5{
                        }
                        h2{
                        }
                        ul{
                            display: none;
                            li{
                            }
                        }
                        .btn{
                            & + .pkg_price{
                            }
                        }
                    }
                    .sImgs{
                        display: none;
                    }
                }
            }
        }
    }

    #home_slider3{
        .carousel-indicators{
            display: none;
            li{
                & + li{
                }
                img{
                }
                &.active{
                }
            }
        }
        .carousel-inner{
            .item{
                height: auto;
                .carousel-caption{
                    padding: 0;
                    height: 510px;
                    .container{
                        .media{
                            padding: 50px 0;
                            vertical-align: middle;
                            .media-left{
                                display: block;
                                width: 300px;
                                margin: 0 auto;
                            }
                            .media-body{
                                text-align: center;
                                padding: 20px 20px 0;
                                h4{
                                }
                                h2{
                                    font-size: 30px;
                                    line-height: 1;
                                }
                                p{
                                    display: none;
                                }
                                .btn{
                                    margin-left: ~"calc(50% - 77px)";
                                    margin-top: 0;
                                    & + .pkg_price{
                                        margin-top: 0;
                                    }
                                    &:hover{
                                        margin-left: ~"calc(50% - 116px)";
                                        & + .pkg_price{
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .carousel-control{
            &.left{}
            &.right{}
        }
    }
}

@media (max-width: 480px){
    .home_slider{
        .item{
            .slide_caption{
                top: ~"calc(50% - 40px)";
                h2{
                    font-size: 30px;
                    margin-bottom: 20px;
                }
                p{
                    display: none;
                }
                .btn{
                    float: none;
                    line-height: 30px;
                    padding: 0 20px;
                    & + .pkg_price{
                        display: none;
                    }
                    &:hover{
                        & + .pkg_price{
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{            
                div{
                    &.owl-prev{
                    }
                    &.owl-next{
                    }
                }
            }
        }

        &.home_slider2{
            .item{
                .slide_caption{
                    .col-sm-6{
                        h5{
                        }
                        h2{
                        }
                        ul{
                            li{
                            }
                        }
                        .btn{
                            & + .pkg_price{
                            }
                        }
                    }
                }
            }
        }
    }
    
    #home_slider3{
        .carousel-indicators{
            li{
                & + li{
                }
                img{
                }
                &.active{
                }
            }
        }
        .carousel-inner{
            .item{
                .slide_bg{
                }
                .carousel-caption{
                    .container{
                        .media{
                            .media-left{
                            }
                            .media-body{
                                h4{
                                }
                                h2{
                                }
                                p{
                                }
                                .btn{
                                    & + .pkg_price{
                                    }
                                    &:hover{
                                        & + .pkg_price{
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .carousel-control{
            &.left{}
            &.right{}
        }
    }
}

@media (max-width: 364px){    
    #home_slider3{
        .carousel-indicators{
            li{
                & + li{
                }
                img{
                }
                &.active{
                }
            }
        }
        .carousel-inner{
            .item{
                .carousel-caption{
                    .container{
                        .media{
                            .media-left{
                            }
                            .media-body{
                                h4{
                                }
                                h2{
                                }
                                p{
                                }
                                .btn{                                    
                                    margin-left: ~"calc(50% - 116px)";
                                    & + .pkg_price{
                                        transform: none;
                                        perspective: none;
                                    }
                                    &:hover{
                                        & + .pkg_price{
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .carousel-control{
            &.left{}
            &.right{}
        }
    }
}