@media (max-width: 1199px){
    .latest_news{
        .post{
            & + .post{
            }
            .media-left{
                a{
                    img{
                    }
                }
            }
            .media-body{
                h5{
                    a{
                    }
                }
                p{
                }
                .timeAgo{
                }
            }
        }
    }

    .blog_content{
    }

    .blog{
        .image{
            a{
                i{
                }
                &:hover{
                    i{                    
                    }
                }
            }
            img{
            }
            .carousel-control{
                .glyphicon{
                }
            }
        }
        .blog_infos{
            li{
                & + li{
                    &:before{
                    }
                }
                a{
                    i{
                    }
                    &:hover{
                    }
                }
            }
        }
        h3{
            a{
                &:hover{
                }
            }
        }
        .btn{
        }
        &.quote_blog{
            .media{
                .media-left{
                }
                .media-body{
                    p{
                    }
                    .quote_writer{
                    }
                }
            }
        }
        & + hr{
        }
    }

    .pagination_nav{
        .pagination{
            li{
                & + li{
                }
                a{
                    &:hover{
                    }
                }
                &:first-child,
                &:last-child{
                    a{
                    }
                }
            }
        }
    }

    .single-blog{
        .blog{
            .img_cap{
                .media-left{
                    a{
                        img{
                        }
                        .caption{
                        }
                    }
                }
                .media-body{
                    h4{
                    }
                    p{
                        & + p{
                        }
                    }
                }
            }
            .quote_row{
                hr{
                }
                blockquote{
                    p{
                    }
                }
            }
        }
    }
}

@media (max-width: 991px){
    .latest_news{
        .post{
            & + .post{
            }
            .media-left{
                a{
                    img{
                    }
                }
            }
            .media-body{
                h5{
                    a{
                    }
                }
                p{
                }
                .timeAgo{
                }
            }
        }
    }

    .blog_content{
    }

    .blog{
        .image{
            a{
                i{
                }
                &:hover{
                    i{                    
                    }
                }
            }
            img{
            }
            .carousel-control{
                .glyphicon{
                }
            }
        }
        .blog_infos{
            li{
                & + li{
                    &:before{
                    }
                }
                a{
                    i{
                    }
                    &:hover{
                    }
                }
            }
        }
        h3{
            a{
                &:hover{
                }
            }
        }
        .btn{
        }
        &.quote_blog{
            .media{
                .media-left{
                }
                .media-body{
                    p{
                    }
                    .quote_writer{
                    }
                }
            }
        }
        & + hr{
        }
    }

    .pagination_nav{
        .pagination{
            li{
                & + li{
                }
                a{
                    &:hover{
                    }
                }
                &:first-child,
                &:last-child{
                    a{
                    }
                }
            }
        }
    }

    .single-blog{
        .blog{
            .img_cap{
                .media-left{
                    a{
                        img{
                        }
                        .caption{
                        }
                    }
                }
                .media-body{
                    h4{
                    }
                    p{
                        & + p{
                        }
                    }
                }
            }
            .quote_row{
                hr{
                }
                blockquote{
                    p{
                    }
                }
            }
        }
    }
}

@media (max-width: 767px){
    .latest_news{
        .post{
            & + .post{
            }
            .media-left{
                a{
                    img{
                    }
                }
            }
            .media-body{
                h5{
                    a{
                    }
                }
                p{
                }
                .timeAgo{
                }
            }
        }
    }

    .blog_content{
    }

    .blog{
        .image{
            a{
                i{
                }
                &:hover{
                    i{                    
                    }
                }
            }
            img{
            }
            .carousel-control{
                .glyphicon{
                }
            }
        }
        .blog_infos{
            li{
                & + li{
                    &:before{
                    }
                }
                a{
                    i{
                    }
                    &:hover{
                    }
                }
            }
        }
        h3{
            a{
                &:hover{
                }
            }
        }
        .btn{
        }
        &.quote_blog{
            .media{
                .media-left{
                }
                .media-body{
                    p{
                    }
                    .quote_writer{
                    }
                }
            }
        }
        & + hr{
        }
    }

    .pagination_nav{
        .pagination{
            li{
                & + li{
                }
                a{
                    &:hover{
                    }
                }
                &:first-child,
                &:last-child{
                    a{
                    }
                }
            }
        }
    }

    .single-blog{
        .blog{
            .img_cap{
                .media-left{
                    a{
                        img{
                        }
                        .caption{
                        }
                    }
                }
                .media-body{
                    h4{
                    }
                    p{
                        & + p{
                        }
                    }
                }
            }
            .quote_row{
                hr{
                }
                blockquote{
                    p{
                    }
                }
            }
        }
    }
}

@media (max-width: 479px){
    .latest_news{
        .post{
            & + .post{
            }
            .media-left{
                a{
                    img{
                    }
                }
            }
            .media-body{
                h5{
                    a{
                    }
                }
                p{
                }
                .timeAgo{
                }
            }
        }
    }

    .blog_content{
    }

    .blog{
        .image{
            a{
                i{
                }
                &:hover{
                    i{                    
                    }
                }
            }
            img{
            }
            .carousel-control{
                .glyphicon{
                }
            }
        }
        .blog_infos{
            li{
                & + li{
                    &:before{
                    }
                }
                a{
                    i{
                    }
                    &:hover{
                    }
                }
            }
        }
        h3{
            a{
                &:hover{
                }
            }
        }
        .btn{
        }
        &.quote_blog{
            .media{
                padding: 40px 20px;
                .media-left{
                    display: block;
                    text-align: center;
                    padding-bottom: 15px;
                }
                .media-body{
                    display: block;
                    width: 100%;
                    p{
                    }
                    .quote_writer{
                    }
                }
            }
        }
        & + hr{
        }
    }

    .pagination_nav{
        .pagination{
            li{
                & + li{
                }
                a{
                    &:hover{
                    }
                }
                &:first-child,
                &:last-child{
                    a{
                    }
                }
            }
        }
    }

    .single-blog{
        .blog{
            .img_cap{
                .media-left{
                    display: block;
                    a{
                        img{
                        }
                        .caption{
                        }
                    }
                }
                .media-body{
                    display: block;
                    width: 100%;
                    h4{
                    }
                    p{
                        & + p{
                        }
                    }
                }
            }
            .quote_row{
                hr{
                }
                blockquote{
                    p{
                    }
                }
            }
        }
    }
}