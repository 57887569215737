@media (max-width: 1199px){
    .facts{
        &.about_us_facts{
        }
    }

    .about_us_content{
        .col-sm-5{
            img{
            }
        }
        .col-sm-7{
            padding-top: 20px;
            .sectionTitle{
                margin-bottom: 40px;
            }
            h4{
            }
            p{
            }
            hr{
            }
            ul{
                li{
                    width: 100%;
                    &:before{
                    }
                    & + li{
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px){
    .facts{
        &.about_us_facts{
        }
    }

    .about_us_content{
        .col-sm-5{
            width: 100%;
            img{
            }
        }
        .col-sm-7{
            width: 100%;
            padding: 50px 15px;            
            .sectionTitle{
            }
            h4{
            }
            p{
            }
            hr{
            }
            ul{
                li{
                    &:before{
                    }
                    & + li{
                    }
                }
            }
        }
    }
}

@media (max-width: 767px){
    .facts{
        &.about_us_facts{
        }
    }

    .about_us_content{
        .col-sm-5{
            img{
            }
        }
        .col-sm-7{
            .sectionTitle{
            }
            h4{
            }
            p{
            }
            hr{
            }
            ul{
                li{
                    &:before{
                    }
                    & + li{
                    }
                }
            }
        }
    }
}