@media (max-width: 1199px){
    .page_header{
        &:before{
        }
        h3{
        }
        .breadcrumb{
            li{
                & + li{
                    &:before{
                    }
                }
                a{
                }
                &.active{
                }
            }
        }
    }

    /*Top and Bottom Padding er each section*/
    .contentRowPad{
        .container{
        }
    }

    .shortcode{
        .contentRowPad{
            .container{
            }
        }
    }

    /*Typography*/
    .typography h1,
    .typography h2,
    .typography h3,
    .typography h4,
    .typography h5,
    .typography h6{
    }
    .typography h6{ 
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
    }

    p{
    }

    /*Title margin bottom for each collumn*/
    .widgetTitle{
    }

    /*Accordion*/
    .accordion,.faqs_accordion{
        .panel{
            & + .panel{
            }
            .panel-heading{
                .panel-title{
                    a{
                        &:before{
                        }
                    }
                }
            } // Panel / Accordion Heading 
            .panel-collapse{
                .panel-body{
                }
            } // Panel / Accordion Body Collapse
        }
    }



    .faqs_section.row{
    }

    .faqs_accordion{
        .panel{
            & + .panel{
            }
            .panel-heading{
                .panel-title{
                    a{
                        &:before{
                        }
                        &:after{
                        }
                    }
                }
            } // Panel / Accordion Heading 
            .panel-collapse{
                .panel-body{
                }
            } // Panel / Accordion Body Collapse
            .media{
                .media-left{
                    span{
                    }
                }
            }
        }
    }

    /*Alerts*/
    .alert{
        & + .alert{
        }
        strong{
        }
        .close{
            i{
            }
        }
        &.alert-warning{
        }
        &.alert-success{
        }
        &.alert-information{
        }
        &.alert-notification{
        }
        &.alert-standard{
        }
    }

    /*Buttons*/
    //--/*Style for shortcode Page*/
    .dl-button{
        dt{
        }
        dd{
            &:last-child{
            }
        }
    }
    //--/*Original Buttion STyle*/
    .btn{
        &:hover{
        }
        &.btn-primary{
            &:hover{
            }
        }
        &.btn-secondary{
            &:hover{
            }
        }
        &.btn-red{
            &:hover{
            }
        }
        &.btn-green{
            &:hover{
            }
        }
    }

    /*Tabs*/
    #exampleTab{
        .exampleTabNav{
            li{
                & + li{
                }
                a{
                }
                &.active{
                    a{
                    }
                }
            }
        }
        .exampleTab_content{
            .tab-pane{
                h5{
                }
                p{                
                }
            }
        }
    }

    /*Columns*/
    //--Styles For Shortcode Page
    .column_row{
        & + .column_row{
        }
        h4{
        }
    }

    /**
    * Columns are follow by Twitter Bootstrap. Please Follow Twitter Bootstrap for this. 
    */

    /*List Row Style*/
    .listRow{
        .widgetTitle{
        }
    }

    /*List*/
    .hostpressUnList{
        li{
            &:before{
            }
            & + li{
            }
        }
    }
    .hostpressOlList{
        li{
            &:before{
            }
            & + li{
            }
        }
    }

    /*Tag*/
    .tag{
        &:hover{
        }
    }


    /*Input Types*/
    //-- Styles for Shortcode Page
    .input_types{
        h3{
        }
        .form-control + h3{
        }
    }

    //--Main Styles
    .form-control{
        & > .btn{
            .filter-option{
            }
        }
    }

    textarea.form-control{
    }


    /*Checkbox & Radio*/
    .checkbox,
    .radio{
        input{
            & + label{
                &:before{
                }
            }
        }
    }
    .checkbox{
        input{
            & + label{
                &:before{
                }
            }
            &:checked + label{
                &:before{
                }
            }
        }
    }
    .radio{
        input{
            & + label{
                &:before{
                }
            }
            &:checked + label{
                &:before{
                }
            }
        }
    }
    
    /*Sections Customize for Responsive*/
    .accordion_alert{
        .col-sm-6{
            width: 100%;
            & + .col-sm-6{
                margin-top: 50px;
            }
        }
    }
}

@media (max-width: 991px){
    .page_header{
        &:before{
        }
        h3{
        }
        .breadcrumb{
            li{
                & + li{
                    &:before{
                    }
                }
                a{
                }
                &.active{
                }
            }
        }
    }

    /*Top and Bottom Padding er each section*/
    .contentRowPad{
        .container{
        }
    }

    .shortcode{
        .contentRowPad{
            .container{
            }
        }
    }

    /*Typography*/
    .typography h1,
    .typography h2,
    .typography h3,
    .typography h4,
    .typography h5,
    .typography h6{
    }
    .typography h6{ 
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
    }

    p{
    }

    /*Title margin bottom for each collumn*/
    .widgetTitle{
    }

    /*Accordion*/
    .accordion,.faqs_accordion{
        .panel{
            & + .panel{
            }
            .panel-heading{
                .panel-title{
                    a{
                        &:before{
                        }
                    }
                }
            } // Panel / Accordion Heading 
            .panel-collapse{
                .panel-body{
                }
            } // Panel / Accordion Body Collapse
        }
    }

    .faqs_section.row{
    }

    .faqs_accordion{
        .panel{
            & + .panel{
            }
            .panel-heading{
                .panel-title{
                    a{
                        letter-spacing: 0;
                        &:before{
                        }
                        &:after{
                        }
                    }
                }
            } // Panel / Accordion Heading 
            .panel-collapse{
                .panel-body{
                }
            } // Panel / Accordion Body Collapse
            .media{
                .media-left{
                    span{
                        padding-right: 30px;
                    }
                }
            }
        }
    }

    /*Alerts*/
    .alert{
        & + .alert{
        }
        strong{
        }
        .close{
            i{
            }
        }
        &.alert-warning{
        }
        &.alert-success{
        }
        &.alert-information{
        }
        &.alert-notification{
        }
        &.alert-standard{
        }
    }

    /*Buttons*/
    //--/*Style for shortcode Page*/
    .dl-button{
        dt{
        }
        dd{
            &:last-child{
            }
        }
    }
    //--/*Original Buttion STyle*/
    .btn{
        &:hover{
        }
        &.btn-primary{
            &:hover{
            }
        }
        &.btn-secondary{
            &:hover{
            }
        }
        &.btn-red{
            &:hover{
            }
        }
        &.btn-green{
            &:hover{
            }
        }
    }

    /*Tabs*/
    #exampleTab{
        .exampleTabNav{
            li{
                & + li{
                }
                a{
                }
                &.active{
                    a{
                    }
                }
            }
        }
        .exampleTab_content{
            .tab-pane{
                h5{
                }
                p{                
                }
            }
        }
    }

    /*Columns*/
    //--Styles For Shortcode Page
    .column_row{
        & + .column_row{
        }
        h4{
        }
    }

    /**
    * Columns are follow by Twitter Bootstrap. Please Follow Twitter Bootstrap for this. 
    */

    /*List Row Style*/
    .listRow{
        .widgetTitle{
        }
    }

    /*List*/
    .hostpressUnList{
        li{
            &:before{
            }
            & + li{
            }
        }
    }
    .hostpressOlList{
        li{
            &:before{
            }
            & + li{
            }
        }
    }

    /*Tag*/
    .tag{
        &:hover{
        }
    }


    /*Input Types*/
    //-- Styles for Shortcode Page
    .input_types{
        h3{
        }
        .form-control + h3{
        }
    }

    //--Main Styles
    .form-control{
        & > .btn{
            .filter-option{
            }
        }
    }

    textarea.form-control{
    }


    /*Checkbox & Radio*/
    .checkbox,
    .radio{
        input{
            & + label{
                &:before{
                }
            }
        }
    }
    .checkbox{
        input{
            & + label{
                &:before{
                }
            }
            &:checked + label{
                &:before{
                }
            }
        }
    }
    .radio{
        input{
            & + label{
                &:before{
                }
            }
            &:checked + label{
                &:before{
                }
            }
        }
    }
    
    /*Sections Customize for Responsive*/
    .accordion_alert{
        .col-sm-6{
            & + .col-sm-6{
            }
        }
    }
    .btn_tab{
        .col-sm-6{
            width: 100%;
            & + .col-sm-6{
                margin-top: 50px;
            }
        }
    }
    .list_tag{
        .col-sm-4{
            width: 100%;
            & + .col-sm-4{
                margin-top: 50px;
            }
        }
    }
}

@media (max-width: 767px){
    .page_header{
        &:before{
        }
        h3{
        }
        .breadcrumb{
            li{
                & + li{
                    &:before{
                    }
                }
                a{
                }
                &.active{
                }
            }
        }
    }

    /*Top and Bottom Padding er each section*/
    .contentRowPad{
        .container{
        }
    }

    .shortcode{
        .contentRowPad{
            .container{
            }
        }
    }

    /*Typography*/
    .typography h1,
    .typography h2,
    .typography h3,
    .typography h4,
    .typography h5,
    .typography h6{
    }
    .typography h6{ 
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
    }

    p{
    }

    /*Title margin bottom for each collumn*/
    .widgetTitle{
    }

    /*Accordion*/
    .accordion,.faqs_accordion{
        .panel{
            & + .panel{
            }
            .panel-heading{
                .panel-title{
                    a{
                        letter-spacing: 0;
                        &:before{
                        }
                    }
                }
            } // Panel / Accordion Heading 
            .panel-collapse{
                .panel-body{
                }
            } // Panel / Accordion Body Collapse
        }
    }



    .faqs_section.row{
    }

    .faqs_accordion{
        .panel{
            & + .panel{
            }
            .panel-heading{
                .panel-title{
                    a{
                        &:before{
                        }
                        &:after{
                        }
                    }
                }
            } // Panel / Accordion Heading 
            .panel-collapse{
                .panel-body{
                }
            } // Panel / Accordion Body Collapse
            .media{
                .media-left{
                    span{
                    }
                }
            }
        }
    }

    /*Alerts*/
    .alert{
        & + .alert{
        }
        strong{
        }
        .close{
            i{
            }
        }
        &.alert-warning{
        }
        &.alert-success{
        }
        &.alert-information{
        }
        &.alert-notification{
        }
        &.alert-standard{
        }
    }

    /*Buttons*/
    //--/*Style for shortcode Page*/
    .dl-button{
        dt{
        }
        dd{
            &:last-child{
            }
        }
    }
    //--/*Original Buttion STyle*/
    .btn{
        &:hover{
        }
        &.btn-primary{
            &:hover{
            }
        }
        &.btn-secondary{
            &:hover{
            }
        }
        &.btn-red{
            &:hover{
            }
        }
        &.btn-green{
            &:hover{
            }
        }
    }

    /*Tabs*/
    #exampleTab{
        .exampleTabNav{
            li{
                & + li{
                }
                a{
                }
                &.active{
                    a{
                    }
                }
            }
        }
        .exampleTab_content{
            .tab-pane{
                h5{
                }
                p{                
                }
            }
        }
    }

    /*Columns*/
    //--Styles For Shortcode Page
    .column_row{
        & + .column_row{
        }
        h4{
        }
    }

    /**
    * Columns are follow by Twitter Bootstrap. Please Follow Twitter Bootstrap for this. 
    */

    /*List Row Style*/
    .listRow{
        .widgetTitle{
        }
    }

    /*List*/
    .hostpressUnList{
        li{
            &:before{
            }
            & + li{
            }
        }
    }
    .hostpressOlList{
        li{
            &:before{
            }
            & + li{
            }
        }
    }

    /*Tag*/
    .tag{
        &:hover{
        }
    }


    /*Input Types*/
    //-- Styles for Shortcode Page
    .input_types{
        h3{
        }
        .form-control + h3{
        }
    }

    //--Main Styles
    .form-control{
        & > .btn{
            .filter-option{
            }
        }
    }

    textarea.form-control{
    }


    /*Checkbox & Radio*/
    .checkbox,
    .radio{
        input{
            & + label{
                &:before{
                }
            }
        }
    }
    .checkbox{
        input{
            & + label{
                &:before{
                }
            }
            &:checked + label{
                &:before{
                }
            }
        }
    }
    .radio{
        input{
            & + label{
                &:before{
                }
            }
            &:checked + label{
                &:before{
                }
            }
        }
    }
    
    /*Sections Customize for Responsive*/
    .accordion_alert{
        .col-sm-6{
            & + .col-sm-6{
            }
        }
    }
    .btn_tab{
        .col-sm-6{
            & + .col-sm-6{
            }
        }
    }
    .list_tag{
        .col-sm-4{
            & + .col-sm-4{
            }
        }
    }
    .input_type,.alignments{
        .col-sm-4{
            width: 100%;
            & + .col-sm-4{
                margin-top: 50px;
            }
        }
    }
}

@media (max-width: 480px){
    .page_header{
        &:before{
        }
        h3{
        }
        .breadcrumb{
            li{
                & + li{
                    &:before{
                    }
                }
                a{
                }
                &.active{
                }
            }
        }
    }

    /*Top and Bottom Padding er each section*/
    .contentRowPad{
        .container{
        }
    }

    .shortcode{
        .contentRowPad{
            .container{
            }
        }
    }

    /*Typography*/
    .typography h1,
    .typography h2,
    .typography h3,
    .typography h4,
    .typography h5,
    .typography h6{
    }
    .typography h6{ 
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
    }

    p{
    }

    /*Title margin bottom for each collumn*/
    .widgetTitle{
    }

    /*Accordion*/
    .accordion,.faqs_accordion{
        .panel{
            & + .panel{
            }
            .panel-heading{
                .panel-title{
                    a{
                        font-weight: 400;
                        line-height: 1;
                        display: block;
                        padding-left: 50px;
                        &:before{
                            margin-top: 0;
                            margin-bottom: 0;
                            margin-left: -50px;
                        }
                    }
                }
            } // Panel / Accordion Heading 
            .panel-collapse{
                .panel-body{
                }
            } // Panel / Accordion Body Collapse
        }
    }



    .faqs_section.row{
    }

    .faqs_accordion{
        .panel{
            & + .panel{
            }
            .panel-heading{
                .panel-title{
                    a{
                        &:before{
                        }
                        &:after{
                        }
                    }
                }
            } // Panel / Accordion Heading 
            .panel-collapse{
                .panel-body{
                }
            } // Panel / Accordion Body Collapse
            .media{
                .media-left{
                    span{
                    }
                }
            }
        }
    }

    /*Alerts*/
    .alert{
        & + .alert{
        }
        strong{
        }
        .close{
            i{
            }
        }
        &.alert-warning{
        }
        &.alert-success{
        }
        &.alert-information{
        }
        &.alert-notification{
        }
        &.alert-standard{
        }
    }

    /*Buttons*/
    //--/*Style for shortcode Page*/
    .dl-button{
        dt{
            width: 100%;
        }
        dd{
            margin-left: 0;
            &:last-child{
            }
        }
    }
    //--/*Original Buttion STyle*/
    .btn{
        &:hover{
        }
        &.btn-primary{
            &:hover{
            }
        }
        &.btn-secondary{
            &:hover{
            }
        }
        &.btn-red{
            &:hover{
            }
        }
        &.btn-green{
            &:hover{
            }
        }
    }

    /*Tabs*/
    #exampleTab{
        .exampleTabNav{
            li{
                width: 100%;
                margin: 0;
                & + li{
                    margin: 0;
                }
                a{
                    text-align: center;
                    padding: 0 20px;
                }
                &.active{
                    a{
                    }
                }
            }
        }
        .exampleTab_content{
            .tab-pane{
                h5{
                }
                p{                
                }
            }
        }
    }

    /*Columns*/
    //--Styles For Shortcode Page
    .column_row{
        & + .column_row{
        }
        h4{
        }
    }

    /**
    * Columns are follow by Twitter Bootstrap. Please Follow Twitter Bootstrap for this. 
    */

    /*List Row Style*/
    .listRow{
        .widgetTitle{
        }
    }

    /*List*/
    .hostpressUnList{
        li{
            &:before{
            }
            & + li{
            }
        }
    }
    .hostpressOlList{
        li{
            &:before{
            }
            & + li{
            }
        }
    }

    /*Tag*/
    .tag{
        &:hover{
        }
    }


    /*Input Types*/
    //-- Styles for Shortcode Page
    .input_types{
        h3{
        }
        .form-control + h3{
        }
    }

    //--Main Styles
    .form-control{
        & > .btn{
            .filter-option{
            }
        }
    }

    textarea.form-control{
    }


    /*Checkbox & Radio*/
    .checkbox,
    .radio{
        input{
            & + label{
                &:before{
                }
            }
        }
    }
    .checkbox{
        input{
            & + label{
                &:before{
                }
            }
            &:checked + label{
                &:before{
                }
            }
        }
    }
    .radio{
        input{
            & + label{
                &:before{
                }
            }
            &:checked + label{
                &:before{
                }
            }
        }
    }
}