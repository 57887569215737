@media (max-width: 1199px){
    .find_domain{
        .domain_search{
            .form_title{
                h5{
                }
                h2{
                }
                p{
                }
            }
            .input-group{
                .form-control{
                }
                .input-group-addon{
                    .searchFilters{
                        .btn{
                            i{
                            }
                            & + ul{
                                li{
                                    a{
                                        &:hover{
                                        }
                                    }
                                }
                            }
                        }
                    }
                    input{
                    }
                }
            }
            .price_list{
                li{
                    padding: 0 30px;
                    & + li{
                    }
                    &:first-child{
                    }
                }
            }
        }
        &.find_domain2{
            .domain_search{
                .form_title{
                    h2{
                    }
                    p{
                    }
                }
                .price_list{
                    li{
                        & + li{
                        }
                    }
                }
                .input-group{
                    .form-control{
                    }
                }
            }
        }
        &.find_domain3{
            .find_domain_cover{
                .container{
                    .row{
                        h2{
                        }
                        p{
                        }
                    }
                }
            }
            .domain_search{
                .input-group{
                    .input-group-addon{
                        input{
                        }
                    }
                }
            }        
        }
    }
}

@media (max-width: 991px){
    .find_domain{
        .domain_search{
            .form_title{
                h5{
                }
                h2{
                }
                p{
                }
            }
            .input-group{
                .form-control{
                }
                .input-group-addon{
                    .searchFilters{
                        .btn{
                            i{
                            }
                            & + ul{
                                li{
                                    a{
                                        &:hover{
                                        }
                                    }
                                }
                            }
                        }
                    }
                    input{
                    }
                }
            }
            .price_list{
                li{
                    padding: 0 12px;
                    & + li{
                        margin: 0;
                    }
                    &:first-child{
                    }
                }
            }
        }
        &.find_domain2{
            .domain_search{
                .form_title{
                    h2{
                    }
                    p{
                    }
                }
                .price_list{
                    li{
                        & + li{
                        }
                    }
                }
                .input-group{
                    .form-control{
                    }
                }
            }
        }
        &.find_domain3{
            .find_domain_cover{
                .container{
                    .row{
                        h2{
                        }
                        p{
                        }
                    }
                }
            }
            .domain_search{
                .input-group{
                    .input-group-addon{
                        input{
                        }
                    }
                }
            }        
        }
    }
}

@media (max-width: 767px){
    .find_domain{
        .domain_search{
            .form_title{
                h5{
                }
                h2{
                    margin-bottom: 5px;
                }
                p{
                    margin-bottom: 10px;
                }
            }
            .input-group{
                .form-control{
                }
                .input-group-addon{
                    .searchFilters{
                        .btn{
                            i{
                            }
                            & + ul{
                                li{
                                    a{
                                        &:hover{
                                        }
                                    }
                                }
                            }
                        }
                    }
                    input{
                    }
                }
            }
            .price_list{
                li{                    
                    & + li{
                    }
                    &:first-child{
                    }
                }
            }
        }
        &.find_domain2{
            .domain_search{
                .form_title{
                    h2{
                    }
                    p{
                    }
                }
                .price_list{
                    li{
                        & + li{
                        }
                    }
                }
                .input-group{
                    .form-control{
                    }
                }
            }
        }
        &.find_domain3{
            .find_domain_cover{
                .container{
                    .row{
                        margin: 0;
                        top: 20%;
                        h2{
                        }
                        p{
                        }
                    }
                }
            }
            .domain_search_container{
                padding: 0;
            }
            .domain_search{
                .input-group{
                    .input-group-addon{
                        input{                            
                        }
                    }
                }
            }        
        }
        &.find_domain_drop{
            &.down{
            }
            & + .drop_icon{
                &:before{
                    display: none;
                }
                .domain_search_drop{
                    border-radius: 0;
                    top: 0;
                    right: 0;
                    width: 40px;
                    line-height: 40px;
                    font-size: 24px;
                    &.rotate{
                        color: transparent;
                        transform: rotate(0);
                        &:after{
                            content: '+';
                            width: 40px;
                            line-height: 40px;
                            border-radius: 100%;
                            top: 0;
                            left: 0;
                            position: absolute;
                            color: #fff;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 599px){
    .find_domain{
        .domain_search{
            .form_title{
                h5{
                }
                h2{
                }
                p{
                }
            }
            .input-group{
                .form-control{
                }
                .input-group-addon{
                    .searchFilters{
                        .btn{
                            padding: 0 15px;
                            width: 100px;
                            margin-left: -100px;
                            i{
                            }
                            & + ul{
                                left: -100px;
                                li{
                                    a{
                                        &:hover{
                                        }
                                    }
                                }
                            }
                        }
                    }
                    input{
                    }
                }
            }
            .price_list{
                padding-left: 1px;
                li{
                    width: ~"calc(100% / 3)";
                    padding: 15px;
                    border: 1px solid #0f91d6;
                    margin-bottom: -1px;
                    margin-left: -1px;
                    text-align: center;
                    & + li{
                        margin-left: -1px;
                        margin-bottom: -1px;
                    }
                    &:first-child{
                        padding: 15px;
                    }
                }
            }
        }
        &.find_domain2{
            .domain_search{
                .form_title{
                    h2{
                    }
                    p{
                    }
                }
                .price_list{
                    li{
                        & + li{
                        }
                    }
                }
                .input-group{
                    .form-control{
                        width: ~"calc(100% - 100px)";
                    }
                }
            }
        }
        &.find_domain3{
            .find_domain_cover{
                .container{
                    .row{
                        h2{
                        }
                        p{
                        }
                    }
                }
            }
            .domain_search{
                .input-group{
                    .input-group-addon{
                        input{
                        }
                    }
                }
            }        
        }
        &.find_domain_drop{
            &.down{
            }
            & + .drop_icon{
                &:before{
                }
                .domain_search_drop{
                    &.rotate{
                    }
                }
            }
        }
    }
}

@media (max-width: 479px){
    .find_domain{
        .domain_search{
            .form_title{
                h5{
                }
                h2{
                }
                p{
                }
            }
            .input-group{
                width: 100%;
                .form-control{
                    width: 100%;
                    display: table;
                }
                .input-group-addon{
                    display: table;
                    width: 100%;
                    .searchFilters{
                        .btn{
                            width: 100px;
                            margin-left: 0;
                            border-left: none;
                            background: @baseColor2;
                            color: #fff;
                            i{
                            }
                            & + ul{
                                left: 0;
                                li{
                                    a{
                                        &:hover{
                                        }
                                    }
                                }
                            }
                        }
                    }
                    input{
                        width: 100%;
                    }
                }
            }
            .price_list{
                li{
                    width: 50%;
                    padding: 15px;
                    border: 1px solid #0f91d6;
                    margin-bottom: -1px;
                    text-align: center;
                    & + li{
                        margin-left: -1px;
                        margin-bottom: -1px;
                    }
                    &:first-child{
                        padding: 15px;
                    }
                }
            }
        }
        &.find_domain2{
            .domain_search{
                .form_title{
                    h2{
                    }
                    p{
                    }
                }
                .price_list{
                    li{
                        & + li{
                        }
                    }
                }
                .input-group{
                    .form-control{
                        width: 100%;
                    }
                    .input-group-addon{
                        .searchFilters{
                            .btn{
                                i{
                                }
                                & + ul{
                                    li{
                                        a{
                                            &:hover{
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        input{
                            width: ~"calc(100% - 100px)";
                        }
                    }
                }
            }
        }
        &.find_domain3{
            .find_domain_cover{
                .container{
                    .row{
                        h2{
                        }
                        p{
                        }
                    }
                }
            }
            .domain_search{
                .input-group{
                    .input-group-addon{
                        input{
                            width: ~"calc(100% - 100px)";
                        }
                    }
                }
            }        
        }
        &.find_domain_drop{
            &.down{
            }
            & + .drop_icon{
                &:before{
                }
                .domain_search_drop{
                    &.rotate{
                    }
                }
            }
        }
    }
}