@media (max-width: 1199px){
    footer{
        .top_footer{
            .quick_contact{
                .nav{
                    li{
                        & + li{
                        }
                        a{
                            padding: 0 33.5px;
                            i{
                            }
                            &:hover{
                            }
                        }
                    }
                }
            }
            .shortKnowledge{
                h4{
                }
                p{
                }
                .about{
                }
                .product{
                    h4{
                    }
                    .product_list{
                        li{
                            &:nth-child(1),&:nth-child(2),&:nth-child(3){
                            }
                            a{
                                &:before{
                                }
                                &:hover{
                                }
                            }
                        }
                    }
                }            
            }
            .beInContact{
                .country_select{
                    button{
                        .flag{
                        }                    
                        i{
                        }
                        &:hover,&:focus{
                        }
                    }
                    .dropdown-menu{
                        li{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
                .social_icos{
                    ul{
                        li{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
                .subscribe_form{
                    .form-inline{
                        .form-group{
                            label{
                                small{
                                }
                            }
                            .input-group{
                                width: 265px;
                                margin-left: 20px;
                                .form-control{
                                }
                                .input-group-addon{                             
                                    input{
                                        &:hover{
                                        }
                                    }
                                }
                            }
                        }                
                    }
                }
            }
        }
        .copyright_line{
        }
    }
}

@media (max-width: 991px){
    footer{
        .top_footer{
            .quick_contact{
                margin-bottom: 40px;
                .nav{
                    li{
                        & + li{
                        }
                        a{
                            line-height: 40px;
                            font-size: 16px;
                            padding: 0 29px;
                            i{
                                font-size: 22px;
                                margin-right: 10px;
                                line-height: 40px;
                            }
                            &:hover{
                            }
                        }
                    }
                }
            }
            .shortKnowledge{
                h4{
                }
                p{
                    line-height: 24px;
                }
                .about{
                }
                .product{
                    h4{
                    }
                    .product_list{
                        li{
                            width: 50%;
                            margin-top: 15px;
                            &:nth-child(1),&:nth-child(2),&:nth-child(3){
                            }
                            &:nth-child(3){
                                margin-top: 15px;
                            }
                            a{
                                &:before{
                                }
                                &:hover{
                                }
                            }
                        }
                    }
                }            
            }
            .beInContact{
                margin-bottom: 30px;
                .country_select{
                    width: 50%;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    button{
                        .flag{
                        }                    
                        i{
                        }
                        &:hover,&:focus{
                        }
                    }
                    .dropdown-menu{
                        li{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
                .social_icos{
                    width: 50%;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-right: none;
                    ul{
                        li{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
                .subscribe_form{                                        
                    border-top: 1px solid #1a2140;
                    padding-left: 0;
                    padding-top: 10px;
                    width: 100%;
                    .form-inline{
                        .form-group{
                            width: 100%;
                            label{
                                width: 125px;
                                small{
                                }
                            }
                            .input-group{
                                width: ~"calc(100% - 125px)";
                                margin-left: 0;
                                .form-control{
                                }
                                .input-group-addon{                             
                                    input{
                                        &:hover{
                                        }
                                    }
                                }
                            }
                        }                
                    }
                }
            }
        }
        .copyright_line{
        }
    }
}

@media (max-width: 767px){
    footer{
        .top_footer{
            .quick_contact{
                padding: 10px 0;
                .nav{
                    li{
                        width: 100%;
                        display: block;
                        float: none;
                        & + li{
                            border: none;
                        }
                        a{
                            padding: 0 20px;
                            i{
                            }
                            &:hover{
                            }
                        }
                    }
                }
            }
            .shortKnowledge{
                h4{
                }
                p{
                }
                .about{
                }
                .product{
                    margin-top: 20px;
                    h4{
                    }
                    .product_list{
                        li{
                            &:nth-child(1),&:nth-child(2),&:nth-child(3){
                            }
                            a{
                                &:before{
                                }
                                &:hover{
                                }
                            }
                        }
                    }
                }            
            }
            .beInContact{
                .country_select{
                    button{
                        .flag{
                        }                    
                        i{
                        }
                        &:hover,&:focus{
                        }
                    }
                    .dropdown-menu{
                        li{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
                .social_icos{
                    ul{
                        li{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
                .subscribe_form{
                    .form-inline{
                        .form-group{
                            label{
                                small{
                                }
                            }
                            .input-group{
                                .form-control{
                                }
                                .input-group-addon{                             
                                    input{
                                        &:hover{
                                        }
                                    }
                                }
                            }
                        }                
                    }
                }
            }
        }
        .copyright_line{
        }
    }
}

@media (max-width: 550px){
    footer{
        .top_footer{
            .quick_contact{
                .nav{
                    li{
                        & + li{
                        }
                        a{
                            i{
                            }
                            &:hover{
                            }
                        }
                    }
                }
            }
            .shortKnowledge{
                h4{
                }
                p{
                }
                .about{
                }
                .product{
                    h4{
                    }
                    .product_list{
                        li{
                            &:nth-child(1),&:nth-child(2),&:nth-child(3){
                            }
                            a{
                                &:before{
                                }
                                &:hover{
                                }
                            }
                        }
                    }
                }            
            }
            .beInContact{
                .country_select{
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid #1a2140;
                    button{
                        .flag{
                        }                    
                        i{
                        }
                        &:hover,&:focus{
                        }
                    }
                    .dropdown-menu{
                        li{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
                .social_icos{
                    width: 100%;
                    ul{
                        li{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
                .subscribe_form{
                    .form-inline{
                        .form-group{
                            label{
                                small{
                                }
                            }
                            .input-group{
                                .form-control{
                                }
                                .input-group-addon{                             
                                    input{
                                        &:hover{
                                        }
                                    }
                                }
                            }
                        }                
                    }
                }
            }
        }
        .copyright_line{
        }
    }
}

@media (max-width: 479px){
    footer{
        .top_footer{
            .quick_contact{
                .nav{
                    li{
                        & + li{
                        }
                        a{
                            i{
                            }
                            &:hover{
                            }
                        }
                    }
                }
            }
            .shortKnowledge{
                h4{
                }
                p{
                }
                .about{
                }
                .product{
                    h4{
                    }
                    .product_list{
                        li{
                            width: 100%;
                            &:nth-child(1),&:nth-child(2),&:nth-child(3){
                            }
                            &:nth-child(2),&:nth-child(3){
                                margin-top: 15px;
                            }
                            a{
                                &:before{
                                }
                                &:hover{
                                }
                            }
                        }
                    }
                }            
            }
            .beInContact{
                .country_select{
                    button{
                        .flag{
                        }                    
                        i{
                        }
                        &:hover,&:focus{
                        }
                    }
                    .dropdown-menu{
                        li{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
                .social_icos{
                    ul{
                        li{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
                .subscribe_form{
                    .form-inline{
                        .form-group{
                            label{
                                width: 100%;
                                small{
                                }
                            }
                            .input-group{
                                width: 100%;
                                margin-top: 15px;
                                .form-control{
                                }
                                .input-group-addon{                             
                                    input{
                                        &:hover{
                                        }
                                    }
                                }
                            }
                        }                
                    }
                }
            }
        }
        .copyright_line{
        }
    }
}
