@media (max-width: 1199px){
    .team_section{
    }

    .team_member{
        .inner{
            .image{
                img{
                }
            }
            h4{
            }
            h6{
            }
            p{
                &.nState{
                }
                &.hState{
                }
            }
            ul{           
                li{
                    & + li{
                    }
                    a{
                    }
                }
            }
            &:hover{
                .image{
                }
                h4{
                }
                h6{
                }
                p{
                    &.nState{
                    }
                    &.hState{
                    }
                }
                ul{
                }
            }
        }
    }
}

@media (max-width: 991px){
    .team_section{
    }

    .team_member{
        width: 50%;
        .inner{
            .image{
                img{
                }
            }
            h4{
            }
            h6{
            }
            p{
                &.nState{
                }
                &.hState{
                }
            }
            ul{           
                li{
                    & + li{
                    }
                    a{
                    }
                }
            }
            &:hover{
                .image{
                }
                h4{
                }
                h6{
                }
                p{
                    &.nState{
                    }
                    &.hState{
                    }
                }
                ul{
                }
            }
        }
    }
}

@media (max-width: 767px){
    .team_section{
    }

    .team_member{
        width: 100%;
        .inner{
            .image{
                img{
                }
            }
            h4{
            }
            h6{
            }
            p{
                &.nState{
                }
                &.hState{
                }
            }
            ul{           
                li{
                    & + li{
                    }
                    a{
                    }
                }
            }
            &:hover{
                .image{
                }
                h4{
                }
                h6{
                }
                p{
                    &.nState{
                    }
                    &.hState{
                    }
                }
                ul{
                }
            }
        }
    }
}