@media (max-width: 1199px){
    .testi_news{
    }

    .testimonial_slider,.testimonial_slider2{
        .item{
            .slide{
                &.slide2{
                }
                .client_img{
                    width: 200px;
                    img{
                    }
                }
                .content{
                    width: ~"calc(100% - 200px)";
                    padding: 15px 15px;
                    .counter{
                        top: 20px;
                    }
                    .quote{
                        p{
                            & + p{
                            }
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{
                div{
                    &.owl-prev{
                    }
                    &.owl-next{                    
                    }
                }
            }
        }
    }
    .testimonial_slider2{
        .owl-controls{
            .owl-nav{
                div{
                    &.owl-prev{
                    }
                    &.owl-next{                    
                    }
                }
            }
        }
    }


    .faqs{
        .panel{
            & + .panel{
            }
            .panel-heading{
                .panel-title{
                    a{
                        letter-spacing: 0;
                        &:before{
                        }
                    }
                }
            } // Panel / Accordion Heading 
            .panel-collapse{
                .panel-body{
                }
            } // Panel / Accordion Body Collapse
        }
    }
}

@media (max-width: 991px){
    .testi_news{
    }

    .testimonial_slider,.testimonial_slider2{
        .item{
            .slide{
                &.slide2{
                }
                .client_img{
                    width: 100%;
                    padding: 0 40px;
                    text-align: center;
                    img{
                    }
                }
                .content{
                    width: 100%;
                    padding-left: 70px;
                    .counter{
                        width: 40px;
                        left: 20px;
                    }
                    .quote{
                        p{
                            & + p{
                            }
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{
                div{
                    &.owl-prev{
                    }
                    &.owl-next{                    
                    }
                }
            }
        }
    }
    .testimonial_slider2{
        .owl-controls{
            .owl-nav{
                div{
                    &.owl-prev{
                    }
                    &.owl-next{                    
                    }
                }
            }
        }
    }


    .faqs{
        .panel{
            & + .panel{
            }
            .panel-heading{
                .panel-title{
                    padding: 10px 0;
                    a{
                        line-height: 24px;
                        display: block;
                        &:before{
                        }
                    }
                }
            } // Panel / Accordion Heading 
            .panel-collapse{
                .panel-body{
                }
            } // Panel / Accordion Body Collapse
        }
    }
}

@media (max-width: 767px){
    .testi_news{
    }

    .testimonial_slider,.testimonial_slider2{
        margin-bottom: 40px;
        .item{
            .slide{
                &.slide2{
                }
                .client_img{
                    img{
                        max-width: 160px;
                        display: inline-block;
                    }
                }
                .content{
                    .counter{
                    }
                    .quote{
                        p{
                            & + p{
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
        .owl-controls{
            .owl-nav{
                div{
                    &.owl-prev{
                    }
                    &.owl-next{                    
                    }
                }
            }
        }
    }
    .testimonial_slider2{
        .owl-controls{
            right: 20px;
            left: auto;
            .owl-nav{
                div{
                    &.owl-prev{
                    }
                    &.owl-next{                    
                    }
                }
            }
        }
    }


    .faqs{
        .panel{
            & + .panel{
            }
            .panel-heading{
                .panel-title{
                    a{
                        display: block;
                        &:before{
                            margin: 6px 0;
                        }
                    }
                }
            } // Panel / Accordion Heading 
            .panel-collapse{
                .panel-body{
                }
            } // Panel / Accordion Body Collapse
        }
    }
}