@media (max-width: 1550px){
    .fluid_header{
        .navbar-header{
            .navbar-brand{
                img{
                }
            }
            .navbar-toggle{
                span{
                }
            }
        } // Navbar Heder
//        .navbar-collapse{
            .navbar-nav{
                &.navbar-left{
                    li{
                        a{
                            i{
                            }
                        }
                        &:first-child{
                            margin: 0 15px;
                            a{
                                i{
                                }
                            }
                        }
                    }
                }
                &.navbar-right{
                    li{
                        a{
                            padding: 0 15px;
                            &:hover,&:focus{
                            }
                        }
                        &.dropdown{
                            a{
                                &:after{
                                }
                            }
                            .-menu{
                                li{
                                    a{
                                        &:after{
                                        }
                                        &:hover{
                                        }
                                    }
                                }                         
                            }
                            &.mega-drop{
                                .mega-menu{
                                    li{
                                        &.service_list{
                                            .service{
                                                .media{
                                                    .media-left{
                                                        a{
                                                            img{
                                                            }
                                                            &:hover{
                                                            }
                                                        }
                                                    }
                                                    .media-body{
                                                        a{
                                                            &:hover{
                                                            }
                                                        }
                                                        p{
                                                        }
                                                    }
                                                }
                                                &:nth-child(even){
                                                }
                                                &:nth-child(1){
                                                }
                                                &:nth-child(2){
                                                }
                                            }
                                        }
                                        &.start_offer{
                                            .inner{
                                                .title{
                                                }
                                                .offered_price{
                                                    small{
                                                    }
                                                }
                                                .regular_price{
                                                    small{
                                                    }
                                                }
                                                .btn{
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.open{
                                a{
                                    &:after{
                                    }                            
                                }
                            }

                            &:hover{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                }
                            }
                        }
                        &.active,&.open{
                            a{
                            }
                            &.dropdown{
                                a{

                                }
                            }
                        }
                        &.login-link{
                            a{
                            }
                        }                    
                    }
                }
            }
//        }
        &.centered{
            .navbar-collapse{
                .navbar-nav{
                    &.navbar-right{
                        li{
                            a{
                                padding: 0 23px;
                                &:hover,&:focus{
                                }
                            }
                            &.dropdown{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                    li{
                                        a{
                                            &:after{
                                            }
                                            &:hover{
                                            }
                                        }
                                    }                         
                                }
                                &.mega-drop{
                                    .mega-menu{
                                        li{
                                            &.service_list{
                                                .service{
                                                    .media{
                                                        .media-left{
                                                            a{
                                                                img{
                                                                }
                                                                &:hover{
                                                                }
                                                            }
                                                        }
                                                        .media-body{
                                                            a{
                                                                &:hover{
                                                                }
                                                            }
                                                            p{
                                                            }
                                                        }
                                                    }
                                                    &:nth-child(even){
                                                    }
                                                    &:nth-child(1){
                                                    }
                                                    &:nth-child(2){
                                                    }
                                                }
                                            }
                                            &.start_offer{
                                                .inner{
                                                    .title{
                                                    }
                                                    .offered_price{
                                                        small{
                                                        }
                                                    }
                                                    .regular_price{
                                                        small{
                                                        }
                                                    }
                                                    .btn{
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.open{
                                    a{
                                        &:after{
                                        }                            
                                    }
                                }

                                &:hover{
                                    a{
                                        &:after{
                                        }
                                    }
                                    .-menu{
                                    }
                                }
                            }
                            &.active,&.open{
                                a{
                                }
                                &.dropdown{
                                    a{

                                    }
                                }
                            }
                            &.login-link{
                                a{
                                }
                            }                    
                        }
                    }
                }
            }
        }
    }

    .top_header{
        .wc_msg{
        }
        .nav{
            li{
                a{
                    i{
                    }
                    &:hover{
                    }
                }
                &:first-child{
                }
                &:nth-child(2){
                }
            }
        }
    }
}

@media (max-width: 1400px){
    .fluid_header{
        .navbar-header{
            .navbar-brand{
                img{
                }
            }
            .navbar-toggle{
                span{
                }
            }
        } // Navbar Heder
//        .navbar-collapse{
            .navbar-nav{
                &.navbar-left{
                    padding-left: 15px;
                    li{
                        float: none;                        
                        a{
                            line-height: 24px;
                            i{
                            }
                        }
                        &:first-child{
                            margin: 15px 0 0;
                            a{
                                i{
                                }
                            }
                        }
                    }
                }
                &.navbar-right{
                    li{
                        a{
                            &:hover,&:focus{
                            }
                        }
                        &.dropdown{
                            a{
                                &:after{
                                }
                            }
                            .-menu{
                                li{
                                    a{
                                        &:after{
                                        }
                                        &:hover{
                                        }
                                    }
                                }                         
                            }
                            &.mega-drop{
                                .mega-menu{
                                    li{
                                        &.service_list{
                                            .service{
                                                .media{
                                                    .media-left{
                                                        a{
                                                            img{
                                                            }
                                                            &:hover{
                                                            }
                                                        }
                                                    }
                                                    .media-body{
                                                        a{
                                                            &:hover{
                                                            }
                                                        }
                                                        p{
                                                        }
                                                    }
                                                }
                                                &:nth-child(even){
                                                }
                                                &:nth-child(1){
                                                }
                                                &:nth-child(2){
                                                }
                                            }
                                        }
                                        &.start_offer{
                                            .inner{
                                                .title{
                                                }
                                                .offered_price{
                                                    small{
                                                    }
                                                }
                                                .regular_price{
                                                    small{
                                                    }
                                                }
                                                .btn{
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.open{
                                a{
                                    &:after{
                                    }                            
                                }
                            }

                            &:hover{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                }
                            }
                        }
                        &.active,&.open{
                            a{
                            }
                            &.dropdown{
                                a{

                                }
                            }
                        }
                        &.login-link{
                            a{
                            }
                        }                    
                    }
                }
            }
//        }
        &.centered{
            .navbar-collapse{
                .navbar-nav{
                    &.navbar-right{
                        li{
                            a{
                                padding: 0 23px;
                                &:hover,&:focus{
                                }
                            }
                            &.dropdown{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                    li{
                                        a{
                                            &:after{
                                            }
                                            &:hover{
                                            }
                                        }
                                    }                         
                                }
                                &.mega-drop{
                                    .mega-menu{
                                        li{
                                            &.service_list{
                                                .service{
                                                    .media{
                                                        .media-left{
                                                            a{
                                                                img{
                                                                }
                                                                &:hover{
                                                                }
                                                            }
                                                        }
                                                        .media-body{
                                                            a{
                                                                &:hover{
                                                                }
                                                            }
                                                            p{
                                                            }
                                                        }
                                                    }
                                                    &:nth-child(even){
                                                    }
                                                    &:nth-child(1){
                                                    }
                                                    &:nth-child(2){
                                                    }
                                                }
                                            }
                                            &.start_offer{
                                                .inner{
                                                    .title{
                                                    }
                                                    .offered_price{
                                                        small{
                                                        }
                                                    }
                                                    .regular_price{
                                                        small{
                                                        }
                                                    }
                                                    .btn{
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.open{
                                    a{
                                        &:after{
                                        }                            
                                    }
                                }

                                &:hover{
                                    a{
                                        &:after{
                                        }
                                    }
                                    .-menu{
                                    }
                                }
                            }
                            &.active,&.open{
                                a{
                                }
                                &.dropdown{
                                    a{

                                    }
                                }
                            }
                            &.login-link{
                                a{
                                }
                            }                    
                        }
                    }
                }
            }
        }
    }

    .top_header{
        .wc_msg{
        }
        .nav{
            li{
                a{
                    i{
                    }
                    &:hover{
                    }
                }
                &:first-child{
                }
                &:nth-child(2){
                }
            }
        }
    }
}

@media (max-width: 1199px){
    .fluid_header{
        .navbar-header{
            width: 100%;
            .navbar-brand{
                padding: 8px 15px;
                img{
                }
            }
            .navbar-toggle{
                span{
                }
            }
        } // Navbar Heder
//        .navbar-collapse{
            .navbar-nav{
                &.navbar-left{
                    float: right !important;                    
                    li{
                        float: left;
                        a{
                            line-height: 50px;
                            i{
                            }
                        }
                        &:first-child{
                            margin: 0 15px;
                            a{
                                i{
                                }
                            }
                        }
                    }
                }
                &.navbar-right{                    
                    li{
                        a{
                            line-height: 50px;
                            &:hover,&:focus{
                            }
                        }
                        &.dropdown{
                            a{
                                &:after{
                                }
                            }
                            .-menu{
                                li{
                                    a{
                                        &:after{
                                        }
                                        &:hover{
                                        }
                                    }
                                }                         
                            }
                            &.mega-drop{
                                .mega-menu{
                                    li{
                                        &.service_list{
                                            padding: 20px 0 20px 20px;
                                            width: ~"calc(100% - 240px)";
                                            .service{
                                                padding-top: 10px;
                                                .media{
                                                    padding: 0 10px 10px 0;
                                                    .media-left{
                                                        a{
                                                            img{
                                                            }
                                                            &:hover{
                                                            }
                                                        }
                                                    }
                                                    .media-body{
                                                        a{
                                                            &:hover{
                                                            }
                                                        }
                                                        p{
                                                            max-width: 100%;
                                                        }
                                                    }
                                                }
                                                &:nth-child(even){
                                                    padding-left: 10px;
                                                }
                                                &:nth-child(1){
                                                }
                                                &:nth-child(2){
                                                }
                                            }
                                        }
                                        &.start_offer{
                                            padding: 17px 20px;
                                            width: 240px;
                                            .inner{
                                                padding: 15px;
                                                .title{
                                                    font-size: 18px;
                                                }
                                                .offered_price{
                                                    font-size: 18px;
                                                    margin-top: 15px;
                                                    small{
                                                    }
                                                }
                                                .regular_price{
                                                    font-size: 12px;
                                                    small{
                                                    }
                                                }
                                                .btn{
                                                    line-height: 30px;
                                                    font-size: 13px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.open{
                                a{
                                    &:after{
                                    }                            
                                }
                            }

                            &:hover{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                }
                            }
                        }
                        &.active,&.open{
                            a{
                            }
                            &.dropdown{
                                a{

                                }
                            }
                        }
                        &.login-link, &.signup-link{
                            margin-top: 5px;
                            a{
                            }
                        }                    
                    }
                }
            }
//        }
        &.centered{
            .navbar-header{
                width: auto;
                .navbar-brand{
                    padding: 21px 15px;
                }
            }
            .navbar-collapse{
                .navbar-nav{
                    &.navbar-right{
                        li{
                            a{
                                padding: 0 15px;
                                line-height: 75px;
                                &:hover,&:focus{
                                }
                            }
                            &.dropdown{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                    li{
                                        a{
                                            &:after{
                                            }
                                            &:hover{
                                            }
                                        }
                                    }                         
                                }
                                &.mega-drop{
                                    .mega-menu{
                                        li{
                                            &.service_list{
                                                .service{
                                                    .media{
                                                        .media-left{
                                                            a{
                                                                img{
                                                                }
                                                                &:hover{
                                                                }
                                                            }
                                                        }
                                                        .media-body{
                                                            a{
                                                                &:hover{
                                                                }
                                                            }
                                                            p{
                                                            }
                                                        }
                                                    }
                                                    &:nth-child(even){
                                                    }
                                                    &:nth-child(1){
                                                    }
                                                    &:nth-child(2){
                                                    }
                                                }
                                            }
                                            &.start_offer{
                                                .inner{
                                                    .title{
                                                    }
                                                    .offered_price{
                                                        small{
                                                        }
                                                    }
                                                    .regular_price{
                                                        small{
                                                        }
                                                    }
                                                    .btn{
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.open{
                                    a{
                                        &:after{
                                        }                            
                                    }
                                }

                                &:hover{
                                    a{
                                        &:after{
                                        }
                                    }
                                    .-menu{
                                    }
                                }
                            }
                            &.active,&.open{
                                a{
                                }
                                &.dropdown{
                                    a{

                                    }
                                }
                            }
                            &.login-link, &.signup-link{
                                margin:20px 15px 0 0;
                                a{
                                    line-height: 35px;
                                }
                            }                    
                        }
                    }
                }
            }
        }
    }
.fluid_header .navbar-nav.navbar-right li a{ text-transform:capitalize;}
    .top_header{
        .col-sm-6{
            width: 60%;
            &.wc_msg{
                width: 40%;
            }
        }
        .nav{
            li{
                a{
                    i{
                    }
                    &:hover{
                    }
                }
                &:first-child{
                    margin-right: 15px;
                }
                &:nth-child(2){
                    margin-right: 15px;
                }
            }
        }
    }
}

@media (max-width: 991px){
    .fluid_header{
        .navbar-header{
            .navbar-brand{
                img{                    
                }
            }
            .navbar-toggle{
                span{
                }
            }
        } // Navbar Heder
//        .navbar-collapse{
            .navbar-nav{
                &.navbar-left{
                    li{
                        a{
                            i{
                            }
                        }
                        &:first-child{
                            a{
                                i{
                                }
                            }
                        }
                    }
                }
                &.navbar-right{
                    li{
                        a{
                            &:hover,&:focus{
                            }
                        }
                        &.dropdown{
                            a{
                                &:after{
                                }
                            }
                            .-menu{
                                li{
                                    a{
                                        &:after{
                                        }
                                        &:hover{
                                        }
                                    }
                                }                         
                            }
                            &.mega-drop{
                                .mega-menu{
                                    li{
                                        &.service_list{
                                            .service{
                                                .media{
                                                    .media-left{
                                                        a{
                                                            img{
                                                            }
                                                            &:hover{
                                                            }
                                                        }
                                                    }
                                                    .media-body{
                                                        a{
                                                            &:hover{
                                                            }
                                                        }
                                                        p{
                                                        }
                                                    }
                                                }
                                                &:nth-child(even){
                                                }
                                                &:nth-child(1){
                                                }
                                                &:nth-child(2){
                                                }
                                            }
                                        }
                                        &.start_offer{
                                            .inner{
                                                .title{
                                                }
                                                .offered_price{
                                                    small{
                                                    }
                                                }
                                                .regular_price{
                                                    small{
                                                    }
                                                }
                                                .btn{
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.open{
                                a{
                                    &:after{
                                    }                            
                                }
                            }

                            &:hover{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                }
                            }
                        }
                        &.active,&.open{
                            a{
                            }
                            &.dropdown{
                                a{

                                }
                            }
                        }
                        &.login-link{
                            a{
                            }
                        }                    
                    }
                }
            }
//        }
        &.centered{
            .container{
                width: 100%;
            }            
            .navbar-header{
                width: auto;
                .navbar-brand{
                    padding: 21px 10px;
                }
            }
            .navbar-collapse{
                .navbar-nav{
                    &.navbar-right{
                        li{
                            a{
                                padding: 0 10px;
                                &:hover,&:focus{
                                }
                            }
                            &.dropdown{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                    li{
                                        a{
                                            &:after{
                                            }
                                            &:hover{
                                            }
                                        }
                                    }                         
                                }
                                &.mega-drop{
                                    .mega-menu{
                                        li{
                                            &.service_list{
                                                .service{
                                                    .media{
                                                        .media-left{
                                                            a{
                                                                img{
                                                                }
                                                                &:hover{
                                                                }
                                                            }
                                                        }
                                                        .media-body{
                                                            a{
                                                                &:hover{
                                                                }
                                                            }
                                                            p{
                                                            }
                                                        }
                                                    }
                                                    &:nth-child(even){
                                                    }
                                                    &:nth-child(1){
                                                    }
                                                    &:nth-child(2){
                                                    }
                                                }
                                            }
                                            &.start_offer{
                                                .inner{
                                                    .title{
                                                    }
                                                    .offered_price{
                                                        small{
                                                        }
                                                    }
                                                    .regular_price{
                                                        small{
                                                        }
                                                    }
                                                    .btn{
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.open{
                                    a{
                                        &:after{
                                        }                            
                                    }
                                }

                                &:hover{
                                    a{
                                        &:after{
                                        }
                                    }
                                    .-menu{
                                    }
                                }
                            }
                            &.active,&.open{
                                a{
                                }
                                &.dropdown{
                                    a{

                                    }
                                }
                            }
                            &.login-link{
                                a{
                                }
                            }                    
                        }
                    }
                }
            }
        }
    }

    .top_header{
        font-size: 13px;
        line-height: 28px;
        .container{
            width: 100%;
        }
        .col-sm-6{
            width: 100%;
            &.wc_msg{
                width: 100%;
            }
        }
        .nav{
            float: left;
            li{
                a{
                    i{
                    }
                    &:hover{
                    }
                }
                &:first-child{
                    margin-right: 15px;
                }
                &:nth-child(2){
                    margin-right: 15px;
                }
            }
        }
    }
}

@media (max-width: 810px){
    .fluid_header{
        .navbar-header{
            .navbar-brand{
                img{                    
                }
            }
            .navbar-toggle{
                span{
                }
            }
        } // Navbar Heder
//        .navbar-collapse{
            .navbar-nav{
                &.navbar-left{
                    li{
                        a{
                            i{
                            }
                        }
                        &:first-child{
                            a{
                                i{
                                }
                            }
                        }
                    }
                }
                &.navbar-right{
                    li{
                        a{
                            &:hover,&:focus{
                            }
                        }
                        &.dropdown{
                            a{
                                &:after{
                                }
                            }
                            .-menu{
                                li{
                                    a{
                                        &:after{
                                        }
                                        &:hover{
                                        }
                                    }
                                }                         
                            }
                            &.mega-drop{
                                .mega-menu{
                                    li{
                                        &.service_list{
                                            .service{
                                                .media{
                                                    .media-left{
                                                        a{
                                                            img{
                                                            }
                                                            &:hover{
                                                            }
                                                        }
                                                    }
                                                    .media-body{
                                                        a{
                                                            &:hover{
                                                            }
                                                        }
                                                        p{
                                                        }
                                                    }
                                                }
                                                &:nth-child(even){
                                                }
                                                &:nth-child(1){
                                                }
                                                &:nth-child(2){
                                                }
                                            }
                                        }
                                        &.start_offer{
                                            .inner{
                                                .title{
                                                }
                                                .offered_price{
                                                    small{
                                                    }
                                                }
                                                .regular_price{
                                                    small{
                                                    }
                                                }
                                                .btn{
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.open{
                                a{
                                    &:after{
                                    }                            
                                }
                            }

                            &:hover{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                }
                            }
                        }
                        &.active,&.open{
                            a{
                            }
                            &.dropdown{
                                a{

                                }
                            }
                        }
                        &.login-link{
                            a{
                            }
                        }                    
                    }
                }
            }
//        }
        &.centered{
            .container{
            }
            .navbar-collapse{
                .navbar-nav{
                    &.navbar-right{
                        li{
                            a{
                                padding: 0 7px;
                                &:hover,&:focus{
                                }
                            }
                            &.dropdown{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                    li{
                                        a{
                                            &:after{
                                            }
                                            &:hover{
                                            }
                                        }
                                    }                         
                                }
                                &.mega-drop{
                                    .mega-menu{
                                        li{
                                            &.service_list{
                                                .service{
                                                    .media{
                                                        .media-left{
                                                            a{
                                                                img{
                                                                }
                                                                &:hover{
                                                                }
                                                            }
                                                        }
                                                        .media-body{
                                                            a{
                                                                &:hover{
                                                                }
                                                            }
                                                            p{
                                                            }
                                                        }
                                                    }
                                                    &:nth-child(even){
                                                    }
                                                    &:nth-child(1){
                                                    }
                                                    &:nth-child(2){
                                                    }
                                                }
                                            }
                                            &.start_offer{
                                                .inner{
                                                    .title{
                                                    }
                                                    .offered_price{
                                                        small{
                                                        }
                                                    }
                                                    .regular_price{
                                                        small{
                                                        }
                                                    }
                                                    .btn{
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.open{
                                    a{
                                        &:after{
                                        }                            
                                    }
                                }

                                &:hover{
                                    a{
                                        &:after{
                                        }
                                    }
                                    .-menu{
                                    }
                                }
                            }
                            &.active,&.open{
                                a{
                                }
                                &.dropdown{
                                    a{

                                    }
                                }
                            }
                            &.login-link{
                                a{
                                }
                            }                    
                        }
                    }
                }
            }
        }
    }

    .top_header{
        font-size: 13px;
        line-height: 24px;
        .container{
            width: 100%;
        }
        .col-sm-6{
            width: 100%;
            &.wc_msg{
                width: 100%;
            }
        }
        .nav{
            li{
                a{
                    font-size: 13px;
                    i{
                    }
                    &:hover{
                    }
                }
                &:first-child{
                    margin-right: 15px;
                }
                &:nth-child(2){
                    margin-right: 15px;
                }
            }
        }
    }
}

@media (max-width: 767px){
    .fluid_header{
        min-height: auto;
        padding: 10px 0;
        .navbar-header{
            .navbar-brand{
                img{
                }
            }
            .navbar-toggle{
                border-radius: 0;
                border-color: transparent;
                background: @baseColor3;
                margin-right: 0;
                span{
                    background: #fff;
                }
            }
        } // Navbar Heder
//        .navbar-collapse{
            .navbar-nav{
                &.navbar-left{
                    float: left !important;
                    margin: 0;
                    li{
                        a{
                            i{
                            }
                        }
                        &:first-child{
                            a{
                                i{
                                }
                            }
                        }
                    }
                }
                &.navbar-right{
                    height:300px;
                    margin: 0;
                    li{
                        a{
                            &:hover,&:focus{
                            }
                        }
                        &.dropdown{
                            a{
                                &:after{
                                }
                            }
                            .-menu{
                                transform: scale(1,1);
                                position: relative;
                                display: block;
                                width: 100%;
                                padding: 0;
                                box-shadow: 0 0 12px rgba(0,0,0,0.175);
                                li{
                                    a{
                                        background: @baseColor2;
                                        color: #FFF;
                                        &:after{
                                        }
                                        &:hover{
                                            background: none;
                                            color: @baseColor2;
                                        }
                                    }
                                }                         
                            }
                            &.mega-drop{
                                .mega-menu{
                                    li{
                                        a{
                                            background: none;
                                        }
                                        &.service_list{
                                            .service{
                                                width: 100%;
                                                .media{
                                                    .media-left{
                                                        a{
                                                            img{
                                                            }
                                                            &:hover{
                                                            }
                                                        }
                                                    }
                                                    .media-body{
                                                        a{
                                                            &:hover{
                                                            }
                                                        }
                                                        p{
                                                        }
                                                    }
                                                }
                                                &:nth-child(even){
                                                    border-left: none;
                                                    padding-left: 0;
                                                }
                                                &:nth-child(1){
                                                }
                                                &:nth-child(2){
                                                    border-top: 1px solid #cde6f0;
                                                    padding-top: 10px;
                                                }
                                            }
                                        }
                                        &.start_offer{
                                            .inner{
                                                .title{
                                                }
                                                .offered_price{
                                                    small{
                                                    }
                                                }
                                                .regular_price{
                                                    small{
                                                    }
                                                }
                                                .btn{
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.open{
                                a{
                                    &:after{
                                    }                            
                                }
                            }

                            &:hover{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                }
                            }
                        }
                        &.active,&.open{
                            a{
                            }
                            &.dropdown{
                                a{

                                }
                            }
                        }
                        &.login-link, &.signup-link{
                            margin: 0 15px;
                            a{
                            }
                        }                    
                    }
                }
            }
//        }
        &.centered{
            padding: 0;
            .navbar-header{
                .navbar-brand{
                    padding: 15px;
                    img{
                    }
                }
                .navbar-toggle{
                    margin: 15px 15px 14px 0;
                    span{
                        background: #fff;
                    }
                }
            } // Navbar Heder
            .navbar-collapse{
                .navbar-nav{
                    &.navbar-right{
                        li{
                            a{
                                line-height: 50px;
                                &:hover,&:focus{
                                }
                            }
                            &.dropdown{
                                a{
                                    &:after{
                                        display: none;
                                    }
                                }
                                .-menu{
                                    li{
                                        a{
                                            &:after{
                                            }
                                            &:hover{
                                            }
                                        }
                                    }                         
                                }
                                &.mega-drop{
                                    .mega-menu{
                                        li{
                                            &.service_list{
                                                .service{
                                                    .media{
                                                        .media-left{
                                                            a{
                                                                img{
                                                                }
                                                                &:hover{
                                                                }
                                                            }
                                                        }
                                                        .media-body{
                                                            a{
                                                                &:hover{
                                                                }
                                                            }
                                                            p{
                                                            }
                                                        }
                                                    }
                                                    &:nth-child(even){
                                                    }
                                                    &:nth-child(1){
                                                    }
                                                    &:nth-child(2){
                                                    }
                                                }
                                            }
                                            &.start_offer{
                                                .inner{
                                                    .title{
                                                    }
                                                    .offered_price{
                                                        small{
                                                        }
                                                    }
                                                    .regular_price{
                                                        small{
                                                        }
                                                    }
                                                    .btn{
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.open{
                                    a{
                                        &:after{
                                        }                            
                                    }
                                }

                                &:hover{
                                    a{
                                        &:after{
                                        }
                                    }
                                    .-menu{
                                    }
                                }
                            }
                            &.active,&.open{
                                a{
                                }
                                &.dropdown{
                                    a{

                                    }
                                }
                            }
                            &.login-link, &.signup-link{
                                margin: 0 0 15px;
                                a{
                                }
                            }                    
                        }
                    }
                }
            }
        }
    }


    .top_header{
        line-height: 35px;
        .wc_msg{
            display: none;
        }
        .nav{
            li{
                a{
                    i{
                    }
                    &:hover{
                    }
                }
                &:first-child{
                }
                &:nth-child(2){
                }
            }
        }
    }
}

@media (max-width: 500px){
    .fluid_header{
        .navbar-header{
            .navbar-brand{
                img{
                }
            }
            .navbar-toggle{
                span{
                }
            }
        } // Navbar Heder
//        .navbar-collapse{
            .navbar-nav{
                &.navbar-left{
                    li{
                        a{
                            i{
                            }
                        }
                        &:first-child{
                            a{
                                i{
                                }
                            }
                        }
                    }
                }
                &.navbar-right{
                    margin: 0;
                    li{
                        a{
                            &:hover,&:focus{
                            }
                        }
                        &.dropdown{
                            a{
                                &:after{
                                }
                            }
                            .-menu{
                                li{
                                    a{
                                        &:after{
                                        }
                                        &:hover{
                                        }
                                    }
                                }                         
                            }
                            &.mega-drop{
                                .mega-menu{
                                    li{
                                        a{
                                        }
                                        &.service_list{
                                            width: 100%;
                                            .service{
                                                .media{
                                                    .media-left{
                                                        a{
                                                            img{
                                                            }
                                                            &:hover{
                                                            }
                                                        }
                                                    }
                                                    .media-body{
                                                        a{
                                                            &:hover{
                                                            }
                                                        }
                                                        p{
                                                        }
                                                    }
                                                }
                                                &:nth-child(even){
                                                }
                                                &:nth-child(1){
                                                }
                                                &:nth-child(2){
                                                }
                                            }
                                        }
                                        &.start_offer{
                                            width: 100%;
                                            padding-top: 0;
                                            .inner{
                                                .title{
                                                }
                                                .offered_price{
                                                    small{
                                                    }
                                                }
                                                .regular_price{
                                                    small{
                                                    }
                                                }
                                                .btn{
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.open{
                                a{
                                    &:after{
                                    }                            
                                }
                            }

                            &:hover{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                }
                            }
                        }
                        &.active,&.open{
                            a{
                            }
                            &.dropdown{
                                a{

                                }
                            }
                        }
                        &.login-link{
                            a{
                            }
                        }                    
                    }
                }
            }
//        }
        &.centered{
            .navbar-header{
                .navbar-brand{
                    img{
                    }
                }
                .navbar-toggle{
                    span{
                        background: #fff;
                    }
                }
            } // Navbar Heder
            .navbar-collapse{
                .navbar-nav{
                    &.navbar-right{
                        li{
                            a{
                                &:hover,&:focus{
                                }
                            }
                            &.dropdown{
                                a{
                                    &:after{
                                    }
                                }
                                .-menu{
                                    li{
                                        a{
                                            &:after{
                                            }
                                            &:hover{
                                            }
                                        }
                                    }                         
                                }
                                &.mega-drop{
                                    .mega-menu{
                                        li{
                                            &.service_list{
                                                .service{
                                                    .media{
                                                        .media-left{
                                                            a{
                                                                img{
                                                                }
                                                                &:hover{
                                                                }
                                                            }
                                                        }
                                                        .media-body{
                                                            a{
                                                                &:hover{
                                                                }
                                                            }
                                                            p{
                                                            }
                                                        }
                                                    }
                                                    &:nth-child(even){
                                                    }
                                                    &:nth-child(1){
                                                    }
                                                    &:nth-child(2){
                                                    }
                                                }
                                            }
                                            &.start_offer{
                                                .inner{
                                                    .title{
                                                    }
                                                    .offered_price{
                                                        small{
                                                        }
                                                    }
                                                    .regular_price{
                                                        small{
                                                        }
                                                    }
                                                    .btn{
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.open{
                                    a{
                                        &:after{
                                        }                            
                                    }
                                }

                                &:hover{
                                    a{
                                        &:after{
                                        }
                                    }
                                    .-menu{
                                    }
                                }
                            }
                            &.active,&.open{
                                a{
                                }
                                &.dropdown{
                                    a{

                                    }
                                }
                            }
                            &.login-link{
                                a{
                                }
                            }                    
                        }
                    }
                }
            }
        }
    }


    .top_header{
        .wc_msg{
        }
        .nav{
            li{
                a{
                    i{
                    }
                    &:hover{
                    }
                }
                &:first-child{
                }
                &:nth-child(2){
                }
            }
        }
    }
}
