@media (max-width:1199px){
    .featureTab{
        .feature_tab_menu{
            .nav{
                li{
                    &:first-child{
                    }
                    a{
                        padding-left: 25px;
                        padding-right: 25px;
                        &:before{
                        }
                        .icon{
                            margin-right: 20px;
                        }
                        &:hover,&:focus{
                            &:before{
                            }
                        }
                    }
                    &:nth-child(1),&:nth-child(2){
                        a{
                        }
                    }
                    & + li{
                        a{
                        }
                    }
                    &.active{
                        a{
                            &:before{
                            }
                        }
                    }
                }
            }
        } // Tab Menu
        .featureTab_contents{
            .tab-content{
                .tab-pane{
                    .content_row{
                        .sectionTitle{
                        }
                        .img_part{
                        }
                        p{
                        }
                        .btn{
                        }
                        &.content_row2{
                            .sectionTitle{
                            }
                        }
                        &.content_row4{
                            .sectionTitle{
                            }
                        }
                        .under_hood{
                            min-height: 185px;
                            .icon{
                            }
                            p{
                            }
                        }
                        .tech_feature{
                            .inner{
                                h4{}
                                h3{
                                }
                                .icon{
                                    padding: 40px 0 30px;
                                }
                            }
                        }
                    }
                }
            }
        } // Tab Content
    }
}

@media (max-width:991px){
    .featureTab{
        .feature_tab_menu{
            .nav{
                li{
                    &:first-child{
                    }
                    a{
                        padding: 15px 10px;
                        height: 80px;
                        &:before{
                        }
                        .icon{
                            margin-right: 10px;
                        }
                        &:hover,&:focus{
                            &:before{
                            }
                        }
                    }
                    &:nth-child(1),&:nth-child(2){
                        a{
                        }
                    }
                    & + li{
                        a{
                        }
                    }
                    &.active{
                        a{
                            &:before{
                            }
                        }
                    }
                }
            }
        } // Tab Menu
        .featureTab_contents{
            .tab-content{
                .tab-pane{
                    .content_row{
                        padding: 65px 0;
                        .sectionTitle{
                        }
                        .img_part{
                        }
                        p{
                        }
                        .btn{
                        }
                        &.content_row2{
                            .sectionTitle{
                            }
                        }
                        &.content_row4{
                            .sectionTitle{
                            }
                        }
                        .under_hood{
                            width: 50%;
                            min-height: auto;
                            .icon{
                            }
                            p{
                            }
                        }
                        .tech_feature{
                            width: 100%;
                            margin-bottom: -1px;
                            .inner{
                                padding: 40px 0;
                                h4{}
                                h3{
                                }
                                .icon{
                                    padding: 40px 15px;
                                }
                                .btn{
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        } // Tab Content
    }
}

@media (max-width:767px){
    .featureTab{
        .feature_tab_menu{
            .nav{
                li{ 
                    width:100%;
                    border-right:0px;

                    &:first-child{
                    border-left:0px;
                    }
                    a{
                        &:before{
                        }
                        .icon{
                        }
                        &:hover,&:focus{
                            &:before{
                            }
                        }
                    }
                    &:nth-child(1),&:nth-child(2){
                        a{
                        }
                    }
                    & + li{
                        a{
                        }
                    }
                    &.active{
                        a{
                            &:before{
                            }
                        }
                    }
                }
            }
        } // Tab Menu
        .featureTab_contents{
            .tab-content{
                .tab-pane{
                    .content_row{
                        .row{
                            margin: 0;
                        }
                        .sectionTitle{
                        }
                        .img_part{
                        }
                        p{
                        }
                        .btn{
                        }
                        &.content_row2{
                            .sectionTitle{
                            }
                        }
                        &.content_row4{
                            .sectionTitle{
                            }
                        }
                        .under_hood{
                            width: 100%;
                            .icon{
                            }
                            p{
                            }
                        }
                        .tech_feature{
                            .inner{
                                h4{}
                                h3{
                                }
                                .icon{
                                }
                            }
                        }
                    }
                }
            }
        } // Tab Content
    }
}