@media(max-width: 1199px){
    .contact_banner{
        .col-sm-6{
            h4{
                span{
                }
            }
            h2{
                i{
                }
            }
            p{
            }
        }
        &.hosting_banner{
        }
    }

    .contact_content{
        .part_number{
        }
        .part_title{
        }
        .form_row{
            h4{
            }
            .form_side{
                width: ~"calc( (100% / 12) * 7 )";
                padding-right: 30px;
            }
            .detail_address{
                width: ~"calc( (100% / 12) * 5 )";
                padding-right: 0;
                padding-left: 30px;
                .media{
                    & + .media{
                        .media-left{
                            i{
                            }
                        }
                        & + .media{
                            .media-left{
                                i{
                                }
                            }
                        }
                    }
                    .media-left{
                        i{
                        }
                    }
                    .media-body{
                        a{
                        }
                        .nav{
                            li{
                                a{
                                    i{
                                    }
                                    &:hover{
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .we_support{
            .part_title{
            }
            .col-sm-4{
                .media{
                    .media-left{
                        i{
                        }
                    }
                    .media-body{
                        h4{
                        }
                    }
                }
                &:nth-child(2){
                    .media{
                        .media-left{
                            i{
                            }
                        }
                    }
                }
                &:nth-child(3){
                    .media{
                        .media-left{
                            i{
                            }
                        }
                    }
                }
            }        
        }
    }

    #mapBox{
        & + .mapbox_hr{
        }
    }

    #contactForm{
        .form-control{
        }
        textarea.form-control{
        }
    }

    .contactForm{
        #success,#error{
        }
        #success{
        }
        #error{
        }
    }
}

@media(max-width: 991px){
    .contact_banner{
        .col-sm-6{
            h4{
                span{
                }
            }
            h2{
                i{
                }
            }
            p{
            }
        }
        &.hosting_banner{
        }
    }

    .contact_content{
        .part_number{
        }
        .part_title{
        }
        .form_row{
            h4{
            }
            .form_side{
                width: 100%;
                padding-right: 0;
                border-right: none;
            }
            .detail_address{
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                .media{
                    & + .media{
                        .media-left{
                            i{
                            }
                        }
                        & + .media{
                            .media-left{
                                i{
                                }
                            }
                        }
                    }
                    .media-left{
                        i{
                        }
                    }
                    .media-body{
                        a{
                        }
                        .nav{
                            li{
                                a{
                                    i{
                                    }
                                    &:hover{
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .we_support{
            .part_title{
            }
            .col-sm-4{
                width: 100%;
                .media{
                    padding-right: 0;
                    .media-left{
                        i{
                        }
                    }
                    .media-body{
                        h4{
                        }
                    }
                }
                &:nth-child(2){
                    margin: 50px 0;
                    .media{
                        .media-left{
                            i{
                            }
                        }
                    }
                }
                &:nth-child(3){
                    .media{
                        .media-left{
                            i{
                            }
                        }
                    }
                }
            }        
        }
    }

    #mapBox{
        & + .mapbox_hr{
        }
    }

    #contactForm{
        .form-control{
        }
        textarea.form-control{
        }
    }

    .contactForm{
        #success,#error{
        }
        #success{
        }
        #error{
        }
    }
}

@media(max-width: 767px){
    .contact_banner{
        .row{
            margin: 0;
            h4{
                span{
                }
            }
            h2{
                i{
                }
            }
            p{
            }
        }
        &.hosting_banner{
        }
    }

    .contact_content{
        .part_number{
        }
        .part_title{
        }
        .form_row{
            h4{
            }
            .form_side{
                padding-left: 15px;
                padding-right: 15px;
            }
            .detail_address{
                padding-left: 15px;
                padding-right: 15px;
                .media{
                    & + .media{
                        .media-left{
                            i{
                            }
                        }
                        & + .media{
                            .media-left{
                                i{
                                }
                            }
                        }
                    }
                    .media-left{
                        i{
                        }
                    }
                    .media-body{
                        a{
                        }
                        .nav{
                            li{
                                a{
                                    i{
                                    }
                                    &:hover{
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .we_support{
            .part_title{
            }
            .col-sm-4{
                .media{
                    .media-left{
                        i{
                        }
                    }
                    .media-body{
                        h4{
                        }
                    }
                }
                &:nth-child(2){
                    .media{
                        .media-left{
                            i{
                            }
                        }
                    }
                }
                &:nth-child(3){
                    .media{
                        .media-left{
                            i{
                            }
                        }
                    }
                }
            }        
        }
    }

    #mapBox{
        & + .mapbox_hr{
        }
    }

    #contactForm{
        .form-control{
        }
        textarea.form-control{
        }
    }

    .contactForm{
        #success,#error{
        }
        #success{
        }
        #error{
        }
    }
}