@media (max-width: 1199px){
    .facts{
        .fact{
            strong{
            }
        }
        &.facts2{
        }
    }
}

@media (max-width: 991px){
    .facts{
        .fact{
            strong{
            }
        }
        &.facts2{
        }
    }
}

@media (max-width: 767px){
    .facts{
        padding-bottom: 20px;
        .fact{
            width: 50%;
            float: left;
            padding-bottom: 40px;
            font-size: 14px;
            strong{
            }
            &:nth-child(3){
                border-left: none;
                border-top: 1px solid #e4e4e4;
                padding-top: 40px;
                padding-bottom: 0;
            }
            &:nth-child(4){
                border-top: 1px solid #e4e4e4;
                padding-top: 40px;
                padding-bottom: 0;
            }
        }
        &.facts2{
        }
    }
}