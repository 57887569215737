@media (max-width: 1199px){
    .hosting_featue{
    }

    .like_us{
    }

    .this_top_features{
        .this_top_feature{
            .media-left{
            }
            .media-right{
            }
            .media-body{
                h5{}
                h2{
                }
            }
            &:nth-child(even){
            }
            &:first-child{}
            &:nth-child(2){
            }
            &:nth-child(3){}
            &:last-child{
            }
        }
    }
}

@media (max-width: 991px){
    .hosting_featue{
    }

    .like_us{
    }

    .this_top_features{
        .this_top_feature{
            .media-left{
                padding-right: 50px;
                img{
                    max-width: 250px;
                }
            }
            .media-right{
                padding-left: 50px;
                img{
                    max-width: 250px;
                }
            }
            .media-body{
                h5{}
                h2{
                }
            }
            &:nth-child(even){
            }
            &:first-child{}
            &:nth-child(2){
            }
            &:nth-child(3){}
            &:last-child{
            }
        }
    }
}

@media (max-width: 767px){
    .hosting_featue{
    }

    .like_us{
        text-align: center;
        .img-responsive{
            display: inline-block;
            width: 95%;
        }
    }

    .this_top_features{
        .this_top_feature{
            .media-left{
                display: block;
                text-align: center;
                padding-right: 0;
                padding-bottom: 30px;
                width: 100%;
                img{
                    display: inline-block;
                    max-width: 90%;
                }
            }
            .media-right{
                float: right;
                width: 100%;
                padding-left: 0;
                padding-top: 30px;
                text-align: center;
                img{
                    display: inline-block;
                    max-width: 90%;
                }
            }
            .media-body{
                h5{}
                h2{
                }
            }
            &:nth-child(even){
            }
            &:first-child{}
            &:nth-child(2){
            }
            &:nth-child(3){}
            &:last-child{
            }
        }
    }
}