@media (max-width: 1199px){
    .pricing.row{
        .sectionTitle{
            h5,h2{
            }
        }
        &.pricing2{
        }
    }

    .pricing_bottom.row{
    }

    .pricing_plan{
        .owl-item{
            *{
            }
            .item{
                .plan{
                    width: 180px;
                    height: 180px;
                    .price{
                        .currencyType{
                        }
                        .amount{
                        }
                        small{
                        }
                    }
                    .serviceType{
                        h4{
                        }
                    }
                    p{
                    }
                    .btn{
                    }
                }
            }
            &.center{
                .item{
                    .plan{
                        width: 380px;
                        height: 380px;
                        margin-left: -100px;
                        .price{
                            .currencyType{
                            }
                            .amount{
                            }
                            small{
                            }                    
                        }
                        .serviceType{
                            h4{
                            }
                        }
                        p{
                        }
                        .btn{
                        }
                    }
                }
            }
        }
    }

    .pricing_nav{
        .col-sm-4{        
        }
        #pricing_nav{
            div{
                &.owl-prev{
                }
                &.owl-next{
                }
            }
        }
    }

    .price_plan{
        .inner{
            .plan_intro{
                &:after{
                }            
                .price{
                    .currencyType{
                    }
                    .amount{
                    }
                    small{
                    }
                }
                .planType{
                    h4{
                    }
                }

            }
            .service_provide_row{
                .service_provide{
                    li{
                        padding: 0 40px;
                    }
                }
            }
        }
        &.best_plan{
            .inner{
                .plan_intro{
                    &:after{
                    }
                    .price{
                        .currencyType{
                        }
                        .amount{
                        }
                        small{
                        }                    
                    }
                    .planType{
                        h4{
                        }
                    }
                }            
                .service_provide_row{
                    .service_provide{
                        li{
                            padding: 0 50px;
                        }
                    }
                    .btn{
                        &:hover{
                        }
                    }
                }
            }
        }
    }

    .pricing_slider_row{
        .pricing_slider{
            .owl-item{
                .item{
                    .domain_ext{
                        &.com{}
                        &.net{}
                        &.in{}
                        &.org{}
                        &.biz{}
                    }
                    .price{
                        .currencyType{
                        }
                        .amount{
                        }
                        small{
                        }
                    }
                    .short_note{
                    }
                    .btn{
                        &:hover,&:focus{
                        }
                    }
                }
            }
            .owl-controls{
                .owl-dots{
                    .owl-dot{
                        &.active{
                        }
                    }
                }
            }
        }
    }

    .domain_price_list{
        ul.nav-tabs{
            &>li{
                &>a{
                    &:hover,&:focus{
                    }
                }
                &.active{
                    a{
                    }
                }
            }
        }
        .p_list_content{
        }
    }

    .pricing_list_table{
        thead{
            tr{
                th{
                    line-height: 24px;
                    padding-top: 15px;
                }
            }
        }
        tbody{
            tr{
                th{
                }
                td{
                }
            }
        }
    }

    .pricing_plan_table{
        .sectionTitle{
        }
    }

    .pricing_plan_cell{
        .plan_type{
            span{
            }
            &.silver,
            &.gold,
            &.diamond{
            }
            &.silver{
            }
            &.gold{
            }
            &.diamond{
            }
        }
        .pricing_row{
            .price{
                .currencyType{
                }
                .amount{
                }
                small{
                }
            }
        }
        .nav_a{
        }
        .nav{
            li{
                .fa-check{
                }
                .fa-times{
                }
            }
        }
        .btn{
            &.visible-none{
            }
            &:hover,&:focus{
            }
        }
    }
}

@media (max-width: 991px){
    .pricing.row{
        .sectionTitle{
            h5,h2{
            }
        }
        &.pricing2{
        }
    }

    .pricing_bottom.row{
    }

    .pricing_plan{
        padding-left: 0;
        .owl-item{
            *{
            }
            .item{
                .plan{
                    .price{
                        .currencyType{
                        }
                        .amount{
                        }
                        small{
                        }
                    }
                    .serviceType{
                        h4{
                        }
                    }
                    p{
                    }
                    .btn{
                    }
                }
            }
            &.center{
                .item{
                    padding: 0;
                    .plan{
                        margin: 0 auto;                        
                        .price{
                            .currencyType{
                            }
                            .amount{
                            }
                            small{
                            }                    
                        }
                        .serviceType{
                            h4{
                            }
                        }
                        p{
                        }
                        .btn{
                        }
                    }
                }
            }
        }
    }

    .pricing_nav{
        .col-sm-4{
            margin: 0 auto;
            width: 380px;
            float: none;
            padding-left: 60px;
        }
        #pricing_nav{
            div{
                &.owl-prev{
                }
                &.owl-next{
                }
            }
        }
    }

    .price_plan{
        padding-top: 20px;
        .inner{
            .plan_intro{
                padding-top: 27px;
                &:after{
                }            
                .price{
                    .currencyType{
                    }
                    .amount{
                    }
                    small{
                    }
                }
                .planType{
                    h4{
                    }
                }

            }
            .service_provide_row{
                padding-bottom: 30px;
                .service_provide{
                    li{
                        padding: 0 15px;
                    }
                }
            }
        }
        &.best_plan{
            .inner{
                .plan_intro{
                    height: 300px;
                    margin-bottom: -150px;
                    &:after{
                    }
                    .price{
                        .currencyType{
                            top: 0;
                        }
                        .amount{
                            font-size: 42px;
                        }
                        small{
                            bottom: -5px;
                        }                    
                    }
                    .planType{
                        h4{
                        }
                    }
                }            
                .service_provide_row{
                    .service_provide{
                        li{
                            padding: 0 20px;
                        }
                    }
                    .btn{
                        &:hover{
                        }
                    }
                }
            }
        }
    }

    .pricing_slider_row{
        .pricing_slider{
            .owl-item{
                .item{
                    .domain_ext{
                        &.com{}
                        &.net{}
                        &.in{}
                        &.org{}
                        &.biz{}
                    }
                    .price{
                        .currencyType{
                        }
                        .amount{
                        }
                        small{
                        }
                    }
                    .short_note{
                    }
                    .btn{
                        &:hover,&:focus{
                        }
                    }
                }
            }
            .owl-controls{
                .owl-dots{
                    .owl-dot{
                        &.active{
                        }
                    }
                }
            }
        }
    }

    .domain_price_list{
        ul.nav-tabs{
            &>li{
                &>a{
                    padding: 0 10px;
                    &:hover,&:focus{
                    }
                }
                &.active{
                    a{
                    }
                }
            }
        }
        .p_list_content{
        }
    }

    .pricing_list_table{
        thead{
            white-space: nowrap;
            tr{
                th{                    
                }
            }
        }
        tbody{
            tr{
                th{
                }
                td{
                }
            }
        }
    }

    .pricing_plan_table{
        .sectionTitle{
        }
    }

    .pricing_plan_cell{
        .plan_type{
            span{
            }
            &.silver,
            &.gold,
            &.diamond{
            }
            &.silver{
            }
            &.gold{
            }
            &.diamond{
            }
        }
        .pricing_row{
            .price{
                .currencyType{
                }
                .amount{
                }
                small{
                }
            }
        }
        .nav_a{
        }
        .nav{
            li{
                .fa-check{
                }
                .fa-times{
                }
            }
        }
        .btn{
            &.visible-none{
            }
            &:hover,&:focus{
            }
        }
    }
}

@media (max-width: 767px){
    .pricing.row{
        .sectionTitle{
            h5,h2{
            }
        }
        &.pricing2{
        }
    }

    .pricing_bottom.row{
    }

    .pricing_plan{
        .owl-item{
            *{
            }
            .item{
                .plan{
                    .price{
                        .currencyType{
                        }
                        .amount{
                        }
                        small{
                        }
                    }
                    .serviceType{
                        h4{
                        }
                    }
                    p{
                    }
                    .btn{
                    }
                }
            }
            &.center{
                .item{
                    .plan{
                        .price{
                            .currencyType{
                            }
                            .amount{
                            }
                            small{
                            }                    
                        }
                        .serviceType{
                            h4{
                            }
                        }
                        p{
                        }
                        .btn{
                        }
                    }
                }
            }
        }
    }

    .pricing_nav{
        .col-sm-4{        
        }
        #pricing_nav{
            div{
                &.owl-prev{
                }
                &.owl-next{
                }
            }
        }
    }

    .price_plan{
        padding-top: 0;
        .inner{            
            width: 350px;
            max-width: 100%;
            margin: 0 auto;
            .plan_intro{
                height: 150px;
                border-radius: 0;
                margin-bottom: 0;
                &:after{
                }            
                .price{
                    .currencyType{
                    }
                    .amount{
                    }
                    small{
                    }
                }
                .planType{
                    h4{
                    }
                }

            }
            .service_provide_row{
                .service_provide{
                    margin: 0 0 15px;
                    li{
                        line-height: 35px;
                    }
                }
            }
        }
        &.best_plan{
            .inner{
                border-bottom: 2px solid @baseColor;
                .plan_intro{
                    margin-bottom: 0;
                    height: 150px;
                    &:after{
                    }
                    .price{
                        .currencyType{
                            font-size: 18px;
                        }
                        .amount{
                            font-size: 36px;
                        }
                        small{
                        }                    
                    }
                    .planType{
                        h4{
                            margin: 0;
                        }
                    }
                }            
                .service_provide_row{
                    .service_provide{
                        li{
                        }
                    }
                    .btn{
                        &:hover{
                        }
                    }
                }
            }
        }
    }

    .pricing_slider_row{
        .pricing_slider{
            .owl-item{
                .item{
                    .domain_ext{
                        &.com{}
                        &.net{}
                        &.in{}
                        &.org{}
                        &.biz{}
                    }
                    .price{
                        .currencyType{
                        }
                        .amount{
                        }
                        small{
                        }
                    }
                    .short_note{
                    }
                    .btn{
                        &:hover,&:focus{
                        }
                    }
                }
            }
            .owl-controls{
                .owl-dots{
                    .owl-dot{
                        &.active{
                        }
                    }
                }
            }
        }
    }

    .domain_price_list{
        ul.nav-tabs{
            &>li{
                &>a{
                    margin: 0;
                    line-height: 50px;
                    &:hover,&:focus{
                    }
                }
                &.active{
                    a{
                    }
                }
            }
        }
        .p_list_content{
        }
    }

    .pricing_list_table{
        thead{
            tr{
                th{
                }
            }
        }
        tbody{
            tr{
                th{
                }
                td{
                }
            }
        }
    }

    .pricing_plan_table{
        .sectionTitle{
        }
        .container{            
            overflow-x: scroll;
        }
        .table-responsive{
            width: 830px;
            overflow-x: scroll;
            padding: 0 15px;
            .pricing_plan_cell{
                width: 199px;
                float: left;
                margin-bottom: 13px;
            }
        }
    }    
    
    .pricing_plan_cell{
        .plan_type{
            span{
            }
            &.silver,
            &.gold,
            &.diamond{
            }
            &.silver{
            }
            &.gold{
            }
            &.diamond{
            }
        }
        .pricing_row{
            .price{
                .currencyType{
                }
                .amount{
                }
                small{
                }
            }
        }
        .nav_a{
        }
        .nav{
            li{
                .fa-check{
                }
                .fa-times{
                }
            }
        }
        .btn{
            &.visible-none{
            }
            &:hover,&:focus{
            }
        }
    }
}

@media (max-width: 420px){
    .pricing.row{
        .sectionTitle{
            h5,h2{
            }
            h2{
                font-size: 40px;
            }
        }
        &.pricing2{
        }
    }

    .pricing_bottom.row{
    }

    .pricing_plan{
        height: 345px;
        .owl-item{
            *{
            }
            .item{
                .plan{
                    .price{
                        .currencyType{
                        }
                        .amount{
                        }
                        small{
                        }
                    }
                    .serviceType{
                        h4{
                        }
                    }
                    p{
                    }
                    .btn{
                    }
                }
            }
            &.center{
                .item{
                    .plan{
                        width: 270px;
                        height: 270px;
                        padding-top: 30px;
                        .price{
                            .currencyType{
                            }
                            .amount{
                            }
                            small{
                            }                    
                        }
                        .serviceType{
                            margin-bottom: 20px;
                            h4{
                            }
                        }
                        p{
                            display: none;
                        }
                        .btn{
                        }
                    }
                }
            }
        }
    }

    .pricing_nav{
        top: 52%;
        .col-sm-4{        
            width: 270px;
            padding: 0 15px;
        }
        #pricing_nav{
            div{
                &.owl-prev{
                }
                &.owl-next{
                }
            }
        }
    }

    .price_plan{
        .inner{
            .plan_intro{
                &:after{
                }            
                .price{
                    .currencyType{
                    }
                    .amount{
                    }
                    small{
                    }
                }
                .planType{
                    h4{
                    }
                }

            }
            .service_provide_row{
                .service_provide{
                    li{
                    }
                }
            }
        }
        &.best_plan{
            .inner{
                .plan_intro{
                    &:after{
                    }
                    .price{
                        .currencyType{
                        }
                        .amount{
                        }
                        small{
                        }                    
                    }
                    .planType{
                        h4{
                        }
                    }
                }            
                .service_provide_row{
                    .service_provide{
                        li{
                        }
                    }
                    .btn{
                        &:hover{
                        }
                    }
                }
            }
        }
    }

    .pricing_slider_row{
        .pricing_slider{
            .owl-item{
                .item{
                    .domain_ext{
                        &.com{}
                        &.net{}
                        &.in{}
                        &.org{}
                        &.biz{}
                    }
                    .price{
                        .currencyType{
                        }
                        .amount{
                        }
                        small{
                        }
                    }
                    .short_note{
                    }
                    .btn{
                        &:hover,&:focus{
                        }
                    }
                }
            }
            .owl-controls{
                .owl-dots{
                    .owl-dot{
                        &.active{
                        }
                    }
                }
            }
        }
    }


    .domain_price_list{
        ul.nav-tabs{
            &>li{
                &>a{
                    &:hover,&:focus{
                    }
                }
                &.active{
                    a{
                    }
                }
            }
        }
        .p_list_content{
        }
    }

    .pricing_list_table{
        thead{
            tr{
                th{
                }
            }
        }
        tbody{
            tr{
                th{
                }
                td{
                }
            }
        }
    }

    .pricing_plan_table{
        .sectionTitle{
        }
    }

    .pricing_plan_cell{
        .plan_type{
            span{
            }
            &.silver,
            &.gold,
            &.diamond{
            }
            &.silver{
            }
            &.gold{
            }
            &.diamond{
            }
        }
        .pricing_row{
            .price{
                .currencyType{
                }
                .amount{
                }
                small{
                }
            }
        }
        .nav_a{
        }
        .nav{
            li{
                .fa-check{
                }
                .fa-times{
                }
            }
        }
        .btn{
            &.visible-none{
            }
            &:hover,&:focus{
            }
        }
    }
}