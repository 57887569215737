@media (max-width: 1199px){
    .sidebar{
        .inner{
            h4{
            }
        }
    }

    .search_form{
        .input-group{
            .form-control{
                & + .input-group-addon{
                    button{
                        i{
                        }
                    }
                }
            }
        }
    }

    .sidebar_line{
    }

    .categories_list,
    .archives_list{
        li{
            & + li{
            }
            a{
                span{
                }
                &:hover{
                }
            }
        }
    }

    .recent_posts{
        .post{
            & + .post{
            }
            .media-left{
            }
            .media-body{
                h5{
                    a{
                        &:hover{
                        }
                    }
                }
                .date{
                    i{
                    }
                }
            }
        }
    }

    .related_posts{
        h4{
        }
        .post{
            .featured_img{
                img{
                }
            }
            h5{
                a{
                    &:hover{
                    }
                }
            }
            .date{
                i{
                }
            }
        }
    }

    .pager{
        li{
            a{
                &:hover,&:focus{
                }
            }
            .post_title{
            }
            &.previous{
                a{                
                    i{
                    }
                }
            }
            &.next{
                a{
                    i{
                    }
                }
            }
        }
    }

    .post_author{
        .media-left{
        }
        .media-body{
            h5{
                i{
                }
                a{
                }
            }
            p{
            }
            .nav{
                li{
                    a{
                        i{
                        }
                        &:hover{
                        }
                    }
                }
            }
        }
    }

    .comments{
        &>h4{
        }
    }

    .comment{
        &.reply_comment{
        }
        & + .comment{
        }
        .media-left{
            a{
                img{
                }
            }
        }
        .media-body{
            h5{
                a{
                }
            }
            .nav{
                li{
                    &+li{
                    }                
                    a{
                    }
                    &:first-child{
                        a{
                        }
                    }
                }
            }
            p{
            }
            .btn{
            }
        }
    }


    .comment_reply{
        h4{
        }
        .form-control{
        }
    }
}

@media (max-width: 991px){
    .sidebar{
        .inner{
            h4{
            }
        }
    }

    .search_form{
        .input-group{
            .form-control{
                & + .input-group-addon{
                    button{
                        i{
                        }
                    }
                }
            }
        }
    }

    .sidebar_line{
    }

    .categories_list,
    .archives_list{
        li{
            & + li{
            }
            a{
                span{
                }
                &:hover{
                }
            }
        }
    }

    .recent_posts{
        .post{
            & + .post{
            }
            .media-left{
            }
            .media-body{
                h5{
                    a{
                        &:hover{
                        }
                    }
                }
                .date{
                    i{
                    }
                }
            }
        }
    }

    .related_posts{
        h4{
        }
        .post{
            .featured_img{
                img{
                }
            }
            h5{
                a{
                    &:hover{
                    }
                }
            }
            .date{
                i{
                }
            }
        }
    }

    .pager{
        li{
            a{
                &:hover,&:focus{
                }
            }
            .post_title{
            }
            &.previous{
                a{                
                    i{
                    }
                }
            }
            &.next{
                a{
                    i{
                    }
                }
            }
        }
    }

    .post_author{
        .media-left{
        }
        .media-body{
            h5{
                i{
                }
                a{
                }
            }
            p{
            }
            .nav{
                li{
                    a{
                        i{
                        }
                        &:hover{
                        }
                    }
                }
            }
        }
    }

    .comments{
        &>h4{
        }
    }

    .comment{
        &.reply_comment{
        }
        & + .comment{
        }
        .media-left{
            a{
                img{
                }
            }
        }
        .media-body{
            h5{
                a{
                }
            }
            .nav{
                li{
                    &+li{
                    }                
                    a{
                    }
                    &:first-child{
                        a{
                        }
                    }
                }
            }
            p{
            }
            .btn{
            }
        }
    }


    .comment_reply{
        h4{
        }
        .form-control{
        }
    }
}

@media (max-width: 767px){
    .sidebar{
        padding-top: 50px;
        .inner{
            padding-left: 0;
            h4{
            }
        }
    }

    .search_form{
        .input-group{
            .form-control{
                & + .input-group-addon{
                    button{
                        i{
                        }
                    }
                }
            }
        }
    }

    .sidebar_line{
    }

    .categories_list,
    .archives_list{
        li{
            & + li{
            }
            a{
                span{
                }
                &:hover{
                }
            }
        }
    }

    .recent_posts{
        .post{
            & + .post{
            }
            .media-left{
            }
            .media-body{
                h5{
                    a{
                        &:hover{
                        }
                    }
                }
                .date{
                    i{
                    }
                }
            }
        }
    }

    .related_posts{
        h4{
        }
        .post{
            .featured_img{
                img{
                }
            }
            h5{
                a{
                    &:hover{
                    }
                }
            }
            .date{
                i{
                }
            }
        }
    }

    .pager{
        li{
            a{
                &:hover,&:focus{
                }
            }
            .post_title{
            }
            &.previous{
                a{                
                    i{
                    }
                }
            }
            &.next{
                a{
                    i{
                    }
                }
            }
        }
    }

    .post_author{
        .media-left{
        }
        .media-body{
            h5{
                i{
                }
                a{
                }
            }
            p{
            }
            .nav{
                li{
                    a{
                        i{
                        }
                        &:hover{
                        }
                    }
                }
            }
        }
    }

    .comments{
        &>h4{
        }
    }

    .comment{
        &.reply_comment{
        }
        & + .comment{
        }
        .media-left{
            a{
                img{
                }
            }
        }
        .media-body{
            h5{
                a{
                }
            }
            .nav{
                li{
                    &+li{
                    }                
                    a{
                    }
                    &:first-child{
                        a{
                        }
                    }
                }
            }
            p{
            }
            .btn{
            }
        }
    }


    .comment_reply{
        h4{
        }
        .form-control{
        }
    }
}

@media (max-width: 479px){
    .sidebar{
        .inner{
            padding-left: 0;
            h4{
            }
        }
    }

    .search_form{
        .input-group{
            .form-control{
                & + .input-group-addon{
                    button{
                        i{
                        }
                    }
                }
            }
        }
    }

    .sidebar_line{
    }

    .categories_list,
    .archives_list{
        li{
            & + li{
            }
            a{
                span{
                }
                &:hover{
                }
            }
        }
    }

    .recent_posts{
        .post{
            & + .post{
            }
            .media-left{
            }
            .media-body{
                h5{
                    a{
                        &:hover{
                        }
                    }
                }
                .date{
                    i{
                    }
                }
            }
        }
    }

    .related_posts{
        h4{
        }
        .post{
            .featured_img{
                img{
                }
            }
            h5{
                a{
                    &:hover{
                    }
                }
            }
            .date{
                i{
                }
            }
        }
    }

    .pager{
        li{
            width: 100%;
            a{
                &:hover,&:focus{
                }
            }
            .post_title{
            }
            &.previous{
                a{                
                    i{
                    }
                }
            }
            &.next{
                padding-top: 30px;
                a{
                    float: left;
                    i{
                    }
                }
                span{
                    float: left;
                }
            }
        }
    }

    .post_author{
        .media-left{
            display: block;
        }
        .media-body{
            width: 100%;
            padding-top: 20px;
            h5{
                i{
                }
                a{
                }
            }
            p{
            }
            .nav{
                li{
                    a{
                        i{
                        }
                        &:hover{
                        }
                    }
                }
            }
        }
    }

    .comments{
        &>h4{
        }
    }

    .comment{
        &.reply_comment{
            margin-left: 30px;
        }
        & + .comment{
        }
        .media-left{
            display: block;
            a{
                img{
                }
            }
        }
        .media-body{
            display: block;
            padding-top: 20px;
            width: 100%;
            h5{
                a{
                }
            }
            .nav{
                li{
                    &+li{
                    }                
                    a{
                    }
                    &:first-child{
                        a{
                        }
                    }
                }
            }
            p{
            }
            .btn{
            }
        }
    }


    .comment_reply{
        h4{
        }
        .form-control{
        }
    }
}