@media (max-width: 1199px){
    .clients{
        .nav{
            li{
                a{
                    padding: 10px;
                    img{
                        max-width: 100%;
                    }
                    &:hover,&:focus{
                    }
                }
            }
        }
    }
}

@media (max-width: 991px){
    .clients{
        .nav{
            li{
                a{
                    &:hover,&:focus{
                    }
                }
            }
        }
    }
}

@media (max-width: 767px){
    .clients{
        text-align: center;
        .nav{
            display: inline-block;
            width: auto;
            li{
                width: 100px;
                float: left;
                a{
                    &:hover,&:focus{
                    }
                }
            }
        }
    }
}