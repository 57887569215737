@media (max-width: 1199px){
    .error_mark{
        img{
        }
        h4{
        }
    }

    .error_msg{
        h1{
        }
        p{
        }
        .btn{
            i{
            }
        }
    }
}

@media (max-width: 991px){
    .error_mark{
        img{
        }
        h4{
        }
    }

    .error_msg{
        h1{
        }
        p{
        }
        .btn{
            i{
            }
        }
    }
}

@media (max-width: 767px){
    .error_mark{
        float: none;
        margin: 100px auto;
        width: 300px;
        height: 300px;
        padding-top: 65px;
        img{
            width: 75%;
        }
        h4{
        }
    }

    .error_msg{
        padding-top: 0;
        padding-bottom: 100px;
        text-align: center;
        h1{
        }
        p{
        }
        .btn{
            i{
            }
        }
    }
}