@media (max-width:1199px){
    .faqs_content{
        h4.menuTitle{
        }
        .faq_category{
            .nav{
                li{
                    a{
                        .icon{
                        }
                        &:hover,&:focus{
                        }
                    }
                    & + li{
                        margin: 0;
                        a{
                        }
                    }
                    &.active{
                        a{
                        }
                    }
                }
            }
        }
        .faq_content{
            .faq{
                &:first-child{
                }
                & +.faq{
                }
                .media-left{
                }
                .media-body{
                    p{
                    }
                }
            }
        }
    }
}

@media (max-width:991px){
    .faqs_content{
        h4.menuTitle{
        }
        .faq_category{
            .nav{
                li{
                    a{
                        .icon{
                        }
                        &:hover,&:focus{
                        }
                    }
                    & + li{
                        a{
                        }
                    }
                    &.active{
                        a{
                        }
                    }
                }
            }
        }
        .faq_content{
            .faq{
                &:first-child{
                }
                & +.faq{
                }
                .media-left{
                }
                .media-body{
                    p{
                    }
                }
            }
        }
    }
}

@media (max-width:767px){
    .faqs_content{
        h4.menuTitle{
        }
        .faq_category{
            width: 100%;
            margin-bottom: 50px;
            .nav{
                li{
                    float: left;
                    width: 50%;
                    a{
                        height: 60px;
                        padding-left: 20px;
                        padding-right: 20px;
                        .icon{
                        }
                        &:hover,&:focus{
                        }
                    }
                    & + li{
                        margin: 0;
                        a{
                        }
                    }
                    &.active{
                        a{
                        }
                    }
                }
            }
        }
        .faq_content{
            width: 100%;
            .faq{
                &:first-child{
                }
                & +.faq{
                }
                .media-left{
                }
                .media-body{
                    p{
                    }
                }
            }
        }
    }
}

@media (max-width:499px){
    .faqs_content{
        h4.menuTitle{
        }
        .faq_category{
            .nav{
                li{
                    width: 100%;
                    a{
                        padding-left: 20px;
                        padding-right: 20px;
                        .icon{
                        }
                        &:hover,&:focus{
                        }
                    }
                    & + li{
                        margin: 0;
                        a{
                        }
                    }
                    &.active{
                        a{
                        }
                    }
                }
            }
        }
        .faq_content{
            .faq{
                &:first-child{
                }
                & +.faq{
                }
                .media-left{
                }
                .media-body{
                    p{
                    }
                }
            }
        }
    }
}