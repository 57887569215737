@media (max-width: 1199px){
    .why_choose{
        &.why_choose2{
        }
    }

    .cause2choose{
        margin-top: 60px;
        .media{
            .media-left{
                a{
                    img{

                    }
                }
            }
            .media-body{
                h4{
                }
            }
        }
        &:nth-child(1),&:nth-child(2),&:nth-child(3){
        }
    }
}

@media (max-width: 991px){
    .why_choose{
        &.why_choose2{
        }
    }

    .cause2choose{
        min-height: 175px;
        .media{
            .media-left{
                a{
                    img{

                    }
                }
            }
            .media-body{
                h4{
                }
            }
        }
        &:nth-child(1),&:nth-child(2),&:nth-child(3){
        }
    }
}

@media (max-width: 767px){
    .why_choose{
        &.why_choose2{
        }
    }

    .cause2choose{
        margin-top: 40px;
        min-height: auto;
        .media{
            .media-left{
                a{
                    img{

                    }
                }
            }
            .media-body{
                h4{
                }
            }
        }
        &:nth-child(1),&:nth-child(2),&:nth-child(3){
        }
        &:nth-child(2),&:nth-child(3){
            margin-top: 40px;
        }
    }
}