@media (max-width: 1199px){
    .serviceTab{
        .service_tab_menu{
            .nav{
                li{
                    a{
                        padding: 25px;
                        &:before{
                        }
                        .icon{
                        }
                        &:hover,&:focus{
                            &:before{
                            }
                        }
                    }
                    & + li{
                        a{
                        }
                    }
                    &.active{
                        a{
                            &:before{
                            }
                        }
                    }
                }
            }
        } // Tab Menu
        .serviceTab_contents{
            .tab-content{
                .tab-pane{
                    .title{
                    }
                    p{
                    }
                    ul{
                    }
                    .service_img{
                    }
                    .service_ico{
                    }
                    .rent{
                        small{
                        }
                    }
                }
            }
        } // Tab Content

        &.serviceTab_byside{
            .service_tab_menu{
                .nav{
                    li{
                        a{
                            padding: 15px 25px;
                            &:before{
                            }
                            .icon{
                                margin-right: 20px;
                            }
                            &:hover,&:focus{
                                &:before{
                                }
                            }
                        }
                        & + li{
                            a{
                            }
                        }
                        &.active{
                            a{
                                &:before{
                                }
                            }
                        }
                    }
                }
            } // Tab Menu
            .serviceTab_contents{
                .tab-content{
                    .tab-pane{
                        .ico_pic{
                            .service_img{
                            }
                            .service_ico{
                            }                        
                        }
                        .texts{
                            .title{
                                margin-top: 60px;
                            }
                            p{
                                margin-bottom: 25px;
                            }
                            ul{
                                margin-bottom: 25px;
                                li + li{
                                    margin-top: 15px;
                                }
                            }
                            .btn{
                                & + .pkg_price{
                                }
                                &:hover{
                                    & + .pkg_price{
                                    }
                                }
                            }
                        }
                    }
                }
            } // Tab Content
        }
    }

    .service_tabs_list{
        .service_tab{
            .ico_price{
                .ico{
                    width: 300px;
                    height: 300px;
                    line-height: 300px;                    
                }
                .rent{
                    left: 240px;
                    small{
                    }
                }
            }
            .content{
                h3{
                }
                p{
                }
                ul{
                    li{
                        letter-spacing: 0;
                        & + li{
                        }
                    }
                }
                .btn{
                }
            }
            &:nth-child(odd){
            }
            &:nth-child(even){
                .ico_price{
                    padding-left: 15px;
                    .ico{
                    }
                }
            }
        }
    }
}

@media (max-width: 991px){
    .serviceTab{
        .service_tab_menu{
            .nav{
                li{
                    a{
                        padding: 25px 20px;
                        &:before{
                        }
                        .icon{
                            max-width: 30px;
                            margin-right: 15px;
                            margin-bottom: 5px;
                        }
                        &:hover,&:focus{
                            &:before{
                            }
                        }
                    }
                    & + li{
                        a{
                        }
                    }
                    &.active{
                        a{
                            &:before{
                            }
                        }
                    }
                }
            }
        } // Tab Menu
        .serviceTab_contents{
            .tab-content{
                .tab-pane{
                    .col-sm-4{width: 45%;}
                    .col-sm-7{
                        width: 55%;
                        margin-left: 0;
                    }
                    .title{
                    }
                    p{
                    }
                    ul{
                    }
                    .service_img{
                    }
                    .service_ico{
                    }
                    .rent{
                        small{
                        }
                    }
                }
            }
        } // Tab Content

        &.serviceTab_byside{
            .service_tab_menu{
                padding-top: 50px;
                .nav{
                    li{
                        a{
                            padding: 15px;
                            &:before{
                            }
                            .icon{
                                margin-right: 10px;
                            }
                            &:hover,&:focus{
                                &:before{
                                }
                            }
                        }
                        & + li{
                            a{
                            }
                        }
                        &.active{
                            a{
                                &:before{
                                }
                            }
                        }
                    }
                }
            } // Tab Menu
            .serviceTab_contents{
                .tab-content{
                    .tab-pane{
                        .ico_pic{
                            .service_img{
                            }
                            .service_ico{
                                width: 120px;
                                height: 120px;
                            }                        
                        }
                        .texts{
                            .title{
                                letter-spacing: 0;
                                margin-top: 0;
                            }
                            p{
                                letter-spacing: 0;
                            }
                            ul{
                                li{
                                    letter-spacing: 0;
                                    &:before{
                                        margin-bottom: 15px;
                                    }
                                }
                            }
                            .btn{
                                margin-bottom: 30px;
                                & + .pkg_price{
                                }
                                &:hover{
                                    & + .pkg_price{
                                    }
                                }
                            }
                        }
                    }
                }
            } // Tab Content
        }
    }

    .service_tabs_list{
        .service_tab{
            .ico_price{
                width: 50%;
                .ico{
                }
                .rent{
                    small{
                    }
                }
            }
            .content{
                width: 50%;
                h3{
                }
                p{
                }
                ul{
                    li{
                        width: 100%;
                        & + li{
                            margin-top: 20px;
                        }
                    }
                }
                .btn{
                    margin-top: 25px;
                }
            }
            &:nth-child(odd){
            }
            &:nth-child(even){
                .ico_price{
                    .ico{
                    }
                }
            }
        }
    }
}

@media (max-width: 767px){
    .serviceTab{
        .service_tab_menu{
            border-bottom: 2px solid @baseColor9;
            .nav{
                margin-bottom: 0;
                li{
                    margin-bottom: 0;
                    a{
                        margin: 0;
                        border: none;
                        background: @baseColor2;
                        color: #fff;
                        line-height: 35px;
                        padding: 10px 20px;
                        &:before{
                            display: none;
                        }
                        .icon{
                            float: none;
                            display: inline-block;
                            margin: 0 15px 0 0;
                        }
                        &:hover,&:focus{
                            &:before{
                            }
                        }
                    }
                    & + li{
                        a{
                            border: none;                            
                        }
                    }
                    &.active{
                        a{
                            border: none;
                            background: @baseColor3;
                            margin-bottom: 0;
                            &:before{
                            }
                        }
                    }
                }
            }
        } // Tab Menu
        .serviceTab_contents{
            .tab-content{
                .tab-pane{
                    .col-sm-4{
                        float: left;
                    }
                    .col-sm-7{
                        float: left;
                    }
                    .title{
                        margin: 40px 0 10px;
                    }
                    p{
                        margin-bottom: 20px;
                    }
                    ul{
                        margin-bottom: 20px;
                        li+li{
                            margin-top: 10px;
                        }
                    }
                    .service_img{
                        max-width: 300px;
                    }
                    .service_ico{
                        max-width: 200px;
                        max-height: 200px;
                    }
                    .rent{
                        top: 165px;
                        left: 120px;
                        width: 80px;
                        height: 80px;
                        padding-top: 20px;
                        font-size: 24px;
                        small{
                            font-size: 12px;
                        }
                    }
                }
            }
        } // Tab Content

        &.serviceTab_byside{
            .service_tab_menu{
                .nav{
                    li{
                        width: 100%;
                        margin-bottom:1px;
                        a{
                            color: @baseColor2;
                            line-height: 21px;
                            &:before{
                            }
                            .icon{
                                margin-top: 5px;
                                float: left;
                            }
                            &:hover,&:focus{
                                &:before{
                                }
                            }
                        }
                        & + li{
                            a{
                            }
                        }
                        &.active{
                            a{
                                &:before{
                                }
                            }
                        }
                    }
                }
            } // Tab Menu
            .serviceTab_contents{
                .tab-content{
                    .tab-pane{
                        .ico_pic{
                            width: 300px;
                            margin: 0 auto 30px;
                            .service_img{
                                float: none;
                            }
                            .service_ico{
                            }                        
                        }
                        .texts{
                            float: none;
                            width: 100%;
                            .title{
                            }
                            p{
                            }
                            ul{
                            }
                            .btn{
                                & + .pkg_price{
                                }
                                &:hover{
                                    & + .pkg_price{
                                    }
                                }
                            }
                        }
                    }
                }
            } // Tab Content
        }
    }


    .service_tabs_list{
        .service_tab{
            .ico_price{
                width: 500px;
                margin: 0 auto;
                .ico{
                }
                .rent{
                    small{
                    }
                }
            }
            .content{
                width: 500px;
                margin: 0 auto;
                h3{
                }
                p{
                }
                ul{
                    li{
                        & + li{
                        }
                    }
                }
                .btn{
                }
            }
            &:nth-child(odd){
            }
            &:nth-child(even){
                .ico_price{
                    width: 500px;
                    float: none;
                    .ico{
                    }
                }
            }
        }
    }
}

@media (max-width: 550px){
    .serviceTab{
        .service_tab_menu{
            .nav{
                li{
                    a{
                        &:before{
                        }
                        .icon{
                        }
                        &:hover,&:focus{
                            &:before{
                            }
                        }
                    }
                    & + li{
                        a{
                        }
                    }
                    &.active{
                        a{
                            &:before{
                            }
                        }
                    }
                }
            }
        } // Tab Menu
        .serviceTab_contents{
            .tab-content{
                .tab-pane{
                    .col-sm-4{
                        width: ~"calc(100% - 160px)";                        
                    }
                    .col-sm-7{
                        width: 160px;
                        padding-top: 100px;
                    }
                    .title{
                    }
                    p{
                    }
                    ul{
                        li+li{
                        }
                    }
                    .service_img{
                        display: none;
                    }
                    .service_ico{
                        position: relative;
                        top: 0;
                        margin: 40px 0;
                        left: 0;
                        width: 100px;
                        height: 100px;
                    }
                    .rent{
                        top: 110px;
                        left: 80px;
                        width: 70px;
                        height: 70px;
                        padding-top: 18px;
                        font-size: 18px;
                        small{
                            font-size: 11px;
                        }
                    }
                }
            }
        } // Tab Content

        &.serviceTab_byside{
            .service_tab_menu{
                .nav{
                    li{
                        a{
                            &:before{
                            }
                            .icon{
                            }
                            &:hover,&:focus{
                                &:before{
                                }
                            }
                        }
                        & + li{
                            a{
                            }
                        }
                        &.active{
                            a{
                                &:before{
                                }
                            }
                        }
                    }
                }
            } // Tab Menu
            .serviceTab_contents{
                .tab-content{
                    .tab-pane{
                        .ico_pic{
                            .service_img{
                            }
                            .service_ico{
                            }                        
                        }
                        .texts{
                            .title{
                            }
                            p{
                            }
                            ul{
                            }
                            .btn{
                                & + .pkg_price{
                                }
                                &:hover{
                                    & + .pkg_price{
                                    }
                                }
                            }
                        }
                    }
                }
            } // Tab Content
        }
    }

    .service_tabs_list{
        .service_tab{
            .ico_price{
                max-width: 100%;
                .ico{
                }
                .rent{
                    small{
                    }
                }
            }
            .content{
                max-width: 100%;
                h3{
                }
                p{
                }
                ul{
                    li{
                        & + li{
                        }
                    }
                }
                .btn{
                }
            }
            &:nth-child(odd){
            }
            &:nth-child(even){
                .ico_price{
                    .ico{
                    }
                }
            }
        }
    }
}

@media (max-width: 479px){
    .serviceTab{
        .service_tab_menu{
            .nav{
                li{
                    a{
                        &:before{
                        }
                        .icon{
                        }
                        &:hover,&:focus{
                            &:before{
                            }
                        }
                    }
                    & + li{
                        a{
                        }
                    }
                    &.active{
                        a{
                            &:before{
                            }
                        }
                    }
                }
            }
        } // Tab Menu
        .serviceTab_contents{
            .tab-content{
                .tab-pane{
                    .col-sm-4{
                        width: 100%;
                    }
                    .col-sm-7{
                        display: none;
                    }
                    .title{
                    }
                    p{
                    }
                    ul{
                        li+li{
                        }
                    }
                    .service_img{
                    }
                    .service_ico{
                    }
                    .rent{
                        small{
                        }
                    }
                }
            }
        } // Tab Content

        &.serviceTab_byside{
            .service_tab_menu{
                .nav{
                    li{
                        a{
                            &:before{
                            }
                            .icon{
                            }
                            &:hover,&:focus{
                                &:before{
                                }
                            }
                        }
                        & + li{
                            a{
                            }
                        }
                        &.active{
                            a{
                                &:before{
                                }
                            }
                        }
                    }
                }
            } // Tab Menu
            .serviceTab_contents{
                .tab-content{
                    .tab-pane{
                        .ico_pic{
                            .service_img{
                            }
                            .service_ico{
                            }                        
                        }
                        .texts{
                            .title{
                            }
                            p{
                            }
                            ul{
                            }
                            .btn{
                                & + .pkg_price{
                                }
                                &:hover{
                                    & + .pkg_price{
                                    }
                                }
                            }
                        }
                    }
                }
            } // Tab Content
        }
    }

    .service_tabs_list{
        .service_tab{
            padding: 70px 0 65px;
            .ico_price{                
                .ico{
                    width: 200px;
                    height: 200px;
                    line-height: 200px;
                    img{
                        max-width: 40%;
                    }
                }
                .rent{
                    top: 10px;
                    left: 170px;
                    width: 90px;
                    height: 90px;
                    padding-top: 20px;
                    small{
                    }
                }
            }
            .content{
                h3{
                }
                p{
                }
                ul{
                    li{
                        & + li{
                        }
                    }
                }
                .btn{
                }
            }
            &:nth-child(odd){
            }
            &:nth-child(even){
                .ico_price{
                    .ico{
                    }
                }
            }
        }
    }
}